import {getPastTwelveMonths} from "../DeviceOverview/DeviceOverviewUtils"

export function createJourneyCountBar(JourneyCountData) {
    const labels = getPastTwelveMonths();
    const data = [
        JourneyCountData?.month11 || 0,
        JourneyCountData?.month10 || 0,
        JourneyCountData?.month9 || 0,
        JourneyCountData?.month8 || 0,
        JourneyCountData?.month7 || 0,
        JourneyCountData?.month6 || 0,
        JourneyCountData?.month5 || 0,
        JourneyCountData?.month4 || 0,
        JourneyCountData?.month3 || 0,
        JourneyCountData?.month2 || 0,
        JourneyCountData?.month1 || 0,
        JourneyCountData?.month0 || 0
    ];

    const allValuesAreZero = data.every(val => val === 0);
    const maxYValue = Math.max(...data) * 1.2;

    const journeyCountBar = {
        labels,
        datasets: [
            {
                label: 'Journey Count Data',
                data,
                backgroundColor: '#0f8dff',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(232,4,183)',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }
        ]
    };

    let delayed;

    const journeyBarOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                clamp: 'true',
                formatter: function (value) {
                    if (value === 0) {
                        return '';
                    }
                    return Math.round(value);
                }
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        let value = context.parsed.y;
                        return `${value.toFixed(0)} journeys`;
                    }
                }
            },
        },
        scales: {
            y: {
                max: allValuesAreZero ? 1 : maxYValue,
                display: false,
            },
            x: {
                ticks: {
                    font: {
                        size: 12
                    },
                    color: 'white',
                }
            }

        },
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 10 + context.datasetIndex * 10;
                }
                return delay;
            },
        },
    }

    return {journeyCountBar, journeyBarOptions}
}

export function createJourneyDurationBar(JourneyDurationData) {
    const labels = getPastTwelveMonths();
    const data = [
        JourneyDurationData?.month11 || 0,
        JourneyDurationData?.month10 || 0,
        JourneyDurationData?.month9 || 0,
        JourneyDurationData?.month8 || 0,
        JourneyDurationData?.month7 || 0,
        JourneyDurationData?.month6 || 0,
        JourneyDurationData?.month5 || 0,
        JourneyDurationData?.month4 || 0,
        JourneyDurationData?.month3 || 0,
        JourneyDurationData?.month2 || 0,
        JourneyDurationData?.month1 || 0,
        JourneyDurationData?.month0 || 0
    ];

    const allValuesAreZero = data.every(val => val === 0);
    const maxYValue = Math.max(...data) * 1.2;

    const journeyDurationBar = {
        labels,
        datasets: [
            {
                label: 'Journey Duration Data',
                data,
                backgroundColor: '#05b053',
                borderWidth: 1,
                hoverBackgroundColor: 'rgb(232,4,183)',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }
        ]
    };
    let delayed;

    const journeyDurationOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                formatter: function (value) {
                    if (value === 0) {
                        return '';
                    } else if (value >= 3600) {
                        const hours = Math.floor(value / 3600);
                        return `${hours}h`;
                    } else if (value < 3600) {
                        const minutes = Math.floor(value / 60);
                        return `${minutes}m`;
                    }
                    return Math.round(value);
                },
                font: {}
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        let value = context.parsed.y / 60;
                        return `${value.toFixed(0)} minutes`;
                    }
                }
            },
            legend: {
                display: false
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: allValuesAreZero ? 1 : maxYValue,
                display: false
            },
            x: {
                ticks: {
                    color: 'white'
                },
            },
        },
        animation: {
            onComplete: () => {
                delayed = true;
            },
            delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 10 + context.datasetIndex * 10;
                }
                return delay;
            },
        },
    };

    return {journeyDurationBar, journeyDurationOptions}
}

export function createJourneyDistanceBar(JourneyDistanceData, ActiveDevices = null, speedUnit) {
    const labels = getPastTwelveMonths();
    let conversionFactor = 1
    if (speedUnit === 0){
        conversionFactor = 0.621371;
    }
    
    
    let data = [];

    for (let i = 11; i >= 0; i--) {
        const monthKey = `month${i}`;
        const rawDistance = JourneyDistanceData?.[monthKey] || 0;
        const distancePerDevice = ActiveDevices ? (rawDistance / (ActiveDevices[monthKey] || 1)) : rawDistance;
        const convertedDistance = distancePerDevice * conversionFactor;
        data.push(convertedDistance || 0);
    }

    const allValuesAreZero = data.every(val => val === 0);
    const maxYValue = Math.max(...data) * 1.2;

    const journeyDistanceBar = {
        labels,
        datasets: [
            {
                label: 'Journey Distance',
                data,
                backgroundColor: '#e66c37',
                borderWidth: 1,
                hoverBackgroundColor: '#e66c37',
                hoverBorderColor: '#e66c37',
            }
        ]
    };
    const journeyDistanceOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                formatter: function (value) {
                    if (value === 0) {
                        return '';
                    } else if (value >= 1000) {
                        return `${(value / 1000).toFixed(0)}k`;
                    }
                    return Math.round(value);
                }
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        let value = context.parsed.y;
                        let unit = speedUnit === 0 ? "miles" : "km";
                        return `${value.toFixed(1)} ${unit}`;
                    }
                }
            }
        },
        scales: {
            y: {
                max: allValuesAreZero ? 1 : maxYValue,
                display: false
            },
            x: {
                ticks: {
                    color: 'white'
                },
            },
        },
    };

    return {journeyDistanceBar, journeyDistanceOptions}
}

export function createJourneyScoresLineChart(JourneyScoresLineData) {
    const analysisTypeLabels = {
        3: 'Overall',
        4: 'Acceleration',
        5: 'Braking',
        6: 'Cornering',
        7: 'Speeding'
    };

    const colors = [
        'rgba(75,192,192,1)',
        'rgba(255,99,132,1)',
        'rgba(54,162,235,1)',
        'rgba(255,206,86,1)',
        'rgba(153,102,255,1)'
    ];

    const labels = getPastTwelveMonths();

    const datasets = JourneyScoresLineData.map((data, index) => {
        const borderWidth = data.analysisTypesId === 3 ? 3 : 1;

        return {
            label: `${analysisTypeLabels[data.analysisTypesId]}`,
            data: [
                data.month11 || 0,
                data.month10 || 0,
                data.month9 || 0,
                data.month8 || 0,
                data.month7 || 0,
                data.month6 || 0,
                data.month5 || 0,
                data.month4 || 0,
                data.month3 || 0,
                data.month2 || 0,
                data.month1 || 0,
                data.month0 || 0
            ],
            fill: false,
            borderColor: colors[index % colors.length],
            borderWidth: borderWidth
        };
    });

    const journeyScoresLineChart = {
        labels,
        datasets,
    };

    const journeyScoresLineChartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
            },
            x: {
                ticks: {
                    color: 'white'
                },
            },
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    usePointStyle: true,
                    generateLabels: function (chart) {
                        const {datasets} = chart.data;
                        const labels = chart.data.labels;

                        return datasets.map((dataset, index) => {
                            const label = `${dataset.label}`;
                            const color = dataset.borderColor;
                            const fillStyle = dataset.backgroundColor;

                            return {
                                text: label,
                                fillStyle: fillStyle || color,
                                strokeStyle: color,
                                lineWidth: 1,
                                hidden: chart.getDatasetMeta(index).hidden,
                                index: index
                            };
                        });
                    }
                }
            }
        }
    };

    return {journeyScoresLineChart, journeyScoresLineChartOptions}
}

export function createJourneyPercentagesBarChart(journeyPercentagesData, ActiveDevicesData = {}) {
    const analysisTypeLabels = {
        9: '0 - 30 mins',
        10: '30mins - 1 hour',
        11: '1+ hour'
    };
    
    const colors = ['rgba(75,192,192,1)', 'rgb(6,90,238)', 'rgb(120,54,235)'];
    const labels = getPastTwelveMonths();

    const datasets = Object.keys(analysisTypeLabels).map((analysisTypeId, index) => {
        const data = journeyPercentagesData.find(item => item.analysisTypesId === parseInt(analysisTypeId, 10));

        const monthData = [];
        for (let i = 11; i >= 0; i--) {
            const month = `month${i}`;
            const activeDevices = ActiveDevicesData[month] || 1;
            const value = data ? (data[month] || 0) : 0;
            monthData.push(value / activeDevices);
        }

        return {
            label: analysisTypeLabels[analysisTypeId],
            data: monthData,
            backgroundColor: colors[index % colors.length],
            borderWidth: 1
        };
    });

    const journeyPercentagesBarChart = {
        labels,
        datasets,
    };

    let delayed;

    const journeyPercentagesBarChartOptions = {
        plugins: {
            legend: {
                display: true
            },
            datalabels: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        if (context.raw !== null && !isNaN(context.raw)) {
                            return context.raw.toFixed(0) + '%';
                        }
                        return '';
                    }
                }
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: 'white'
                },
            },
            y: {
                stacked: true,
                max: 100,
                display: false
            }
        },
    };


    return {journeyPercentagesBarChart, journeyPercentagesBarChartOptions};
}

export function createSpeedingAverageLineChart(SpeedingAverage, ActiveDeviceData = null) {
    const analysisTypeLabels = {
        14: 'Speeding'
    };
    const color = 'rgba(224,25,31,0.7)';
    

    const labels = getPastTwelveMonths();
    let dataPoints = [];

    for (let i = 11; i >= 0; i--) {
        const monthKey = `month${i}`;
        let speedValue = SpeedingAverage[monthKey] !== 0 ? ((SpeedingAverage[monthKey]) * 100) -100 : 0;
        if (speedValue < 0){
            speedValue = 0;
        }
        dataPoints.push(speedValue);
    }

    const dataset = {
        label: analysisTypeLabels[14],
        data: dataPoints,
        fill: true,
        borderColor: color,
        backgroundColor: color,
        borderWidth: 1,
    };

    const allValuesAreZero = dataPoints.every(val => val === 0);
    const maxYValue = Math.max(...dataPoints) * 1.2;

    const speedingLineChart = {
        labels,
        datasets: [dataset]
    };

    const speedingLineChartOptions = {
            scales: {
                y: {
                    max: allValuesAreZero ? 1 : maxYValue,
                    display: false
                },
                x: {
                    ticks: {
                        color: 'white'
                    },
                },
        },
        plugins: {
            tooltip: {
                enabled: false
            },
            legend: {
                display: false
            },
            datalabels: {
                display: true,
                color: 'white',
                anchor: 'end',
                align: 'top',
                font: {},
                formatter: (value) => {
                    if (value === 0) {
                        return '';
                    }
                    return value.toFixed(0) + '%';
                }
            }
        }
    };


    return {speedingLineChart, speedingLineChartOptions}
}