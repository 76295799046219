import React, {useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import './EditUser.css';

const EditUser = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [NewUserData, setNewUserData] = useState([]);
    const [UserData, setUserData] = useState([]);
    const [Username, setUsername] = useState("");
    const [DefaultCompany, setDefaultCompany] = useState("");
    const [DefaultUnit, setDefaultUnit] = useState("");
    const [companyname, setCompanyname] = useState("");
    let [speedUnit, setSpeedUnit] = useState("0");

    useEffect(() => {
        document.title = "Edit User";
        const urlParam = location.search;
        let urlValue = urlParam.substring(1);
        let username = urlValue.replace(/%20/g, " ");
        setUsername(username);

        getAllCompanies();
        getUser(username);
    }, [location.search]);

    const getAllCompanies = async () => {
        const response = await fetch(`/api/UserManagement/GetAllCompanies`, GetHttpConfig(props.userAccessToken));
        const data = await response.json();
        setNewUserData(data);
        setDefaultCompany(data[0].name);
    };

    const getUser = async (username) => {
        const response = await fetch(`/api/UserManagement/GetSingleUser?Username=${username}`, GetHttpConfig(props.userAccessToken));
        const data = await response.json();
        setUserData(data);
        setSpeedUnit(data.speedUnit.toString());
        setCompanyname(data.companyName);
    };

    const handleEditUser = async (username, companyname, e) => {
        e.preventDefault();
        
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
            body: JSON.stringify({
                username,
                companyName: companyname,
                isAdmin: false,
                speedUnit: speedUnit
            })
        };

        const response = await fetch('/api/UserManagement/EditUser', requestOptions);
        if (response.ok) {
            navigate('/admin/usermanagement');
        } else {
            console.error('Failed to edit user');
        }
    };


    const handleCompanynameChange = (event) => {
        setCompanyname(event.target.value);
    };

    const handleSpeedUnitChange = (event) => {
        setSpeedUnit(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Edit User</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={(e) => handleEditUser(Username, companyname, e)} className="form-container">
                                <Form.Group controlId="username">
                                    <Form.Label>Current User</Form.Label>
                                    <Form.Control
                                        type="text"
                                        readOnly
                                        value={Username}/>
                                </Form.Group>
                                <Form.Group controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control as="select" value={companyname} onChange={handleCompanynameChange}>
                                        <option>Select Company</option>
                                        {NewUserData.map(company => (
                                            <option value={company.name} key={company.name}>{company.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="speedUnit">
                                    <Form.Label>Measurement Unit (Requires browser refresh)</Form.Label>
                                    <Form.Control as="select" value={speedUnit} onChange={handleSpeedUnitChange}>
                                        <option value="0">Imperial</option>
                                        <option value="1">Metric</option>
                                    </Form.Control>
                                </Form.Group>

                                <Button variant="primary" type="submit">Edit User</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};
export default EditUser;
