import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './MoveDevice.css';

const DeviceCustomerSwitchForm = (props) => {
    const [companies, setCompanies] = useState([]);
    const [formData, setFormData] = useState({
        deviceReference: '',
        newCustomerReference: ''
    });
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const deviceReferenceFromQuery = query.get('deviceReference');

    const navigate = useNavigate();

    useEffect(() => {
        setFormData(formData => ({
            ...formData,
            deviceReference: deviceReferenceFromQuery || ''
        }));

        const fetchCompanies = async () => {
            try {
                const response = await fetch('/api/CompanyManagement/GetAllCompanies', {
                    headers: {
                        'Authorization': 'Bearer ' + props.userAccessToken
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch companies');
                }
                const data = await response.json();
                setCompanies(data);
            } catch (error) {
                console.error('Error fetching companies:', error);
                toast.error('Error fetching companies');
            }
        };

        fetchCompanies();
    }, [deviceReferenceFromQuery]);


    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify(formData)
        };

        try {
            const response = await fetch('/api/Register/SwitchDeviceCustomer', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            toast.success('Device moved customer successfully');
            navigate('/vehicle-management');
        } catch (error) {
            console.error('Error during device customer movement:', error);
            toast.error(`Error during customer device movement: ${error.message}`);
        }
    };

    return (
        <div>
            <ToastContainer position="top-right" autoClose={5000}/>
            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} className="fixed-col2">
                            <div className="contentvehicle3" style={{gridColumn: '1 / -1', textAlign: 'center'}}>
                                <h5>Switch Customer</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} lg={3} className=""></Col>
                        <Col md={6} lg={6} className="">
                            <div className="container mt-5">
                                <form onSubmit={handleSubmit} className="form-container">
                                    <div className="mb-3">
                                        <label htmlFor="deviceReference" className="form-label">
                                            Device Reference:
                                        </label>
                                        <input type="text" className="form-control"
                                               name="deviceReference" id="deviceReference"
                                               value={formData.deviceReference} onChange={handleChange} readOnly/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="newCustomerReference" className="form-label">
                                            New Customer:
                                        </label>
                                        <select className="form-control"
                                                name="newCustomerReference" id="newCustomerReference"
                                                value={formData.newCustomerReference} onChange={handleChange}>
                                            <option value="">Select a new customer</option>
                                            {companies.map(company => (
                                                <option key={company.companyId} value={company.customerReference}>
                                                    {company.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <Button type="submit" className="btn btn-primary">Switch Customer</Button>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default DeviceCustomerSwitchForm;
