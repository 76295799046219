import React, {useState} from 'react';
import { Button, Nav } from 'react-bootstrap';
import {
    FaBell, FaBuilding, FaCar, FaCarCrash, FaChartLine, FaMap,
    FaTable, FaUser, FaUserFriends, FaUsers
} from "react-icons/fa";
import './NavMenu.css';
import logo from './images/AGMTechnologies_White.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Loading from "./Loading";

const NavMenu = React.memo(
    ({ isAdmin = null, authority, userEmail, navVisible, toggleNav }) => {
        const [isMobileNavVisible, setIsMobileNavVisible] = React.useState(false);
        const toggleMobileNav = () => {
            setIsMobileNavVisible(!isMobileNavVisible);
        };

        const myAccount = () => {
            window.location.href = identityServicerUrl('');
        };

        const identityServicerUrl = (controller) => {
            let authorityCopy = authority;
            authorityCopy = authorityCopy[authorityCopy.length - 1] !== '/' ? authorityCopy + '/' : authorityCopy;
            return authorityCopy + controller;
        };

        if (isAdmin == null) {
            return <Loading />;
        }

        return (
            <>
                <div className={`sidebar ${isMobileNavVisible ? 'visible' : ''}`}>
                    <div>
                        <div className="brand">
                            <a href=""><img src={logo} onClick={(e) => {
                                e.preventDefault();
                                myAccount();
                            }} alt="Logo"/></a>
                        </div>
                        <Nav className="flex-column">
                            <Link to="/" className="nav-link-custom" onClick={toggleMobileNav}>
                                <div className="nav-link-content">
                                    <FaChartLine className="icon"/> Overview
                                </div>
                            </Link>
                            <Link to="/vehicle-list" className="nav-link-custom" onClick={toggleMobileNav}>
                                <div className="nav-link-content">
                                    <FaCar className="icon"/> Vehicles
                                </div>
                            </Link>
                            <Link to="/last-activity" className="nav-link-custom" onClick={toggleMobileNav}>
                                <div className="nav-link-content">
                                    <FaMap className="icon"/> Map
                                </div>
                            </Link>
                            <Link to="/crash/crash-list" className="nav-link-custom" onClick={toggleMobileNav}>
                                <div className="nav-link-content">
                                    <FaCarCrash className="icon"/> Crash
                                </div>
                            </Link>
                            <Link to="/tamper-list" className="nav-link-custom" onClick={toggleMobileNav}>
                                <div className="nav-link-content">
                                    <FaBell className="icon"/> Tamper
                                </div>
                            </Link>
                            <Link to="/leaderboards" className="nav-link-custom" onClick={toggleMobileNav}>
                                <div className="nav-link-content">
                                    <FaTable className="icon"/> Leaderboards
                                </div>
                            </Link>
                            {isAdmin && (
                                <>
                                    <Link to="/vehicle-management" className="nav-link-custom" onClick={toggleMobileNav}>
                                        <div className="nav-link-content">
                                            <FaUsers className="icon"/> Vehicle Management
                                        </div>
                                    </Link>
                                    <Link to="/admin/usermanagement" className="nav-link-custom" onClick={toggleMobileNav}>
                                        <div className="nav-link-content">
                                            <FaUsers className="icon"/> Users
                                        </div>
                                    </Link>
                                    <Link to="/admin/groupmanagement" className="nav-link-custom" onClick={toggleMobileNav}>
                                        <div className="nav-link-content">
                                            <FaUserFriends className="icon"/> Groups
                                        </div>
                                    </Link>
                                    <Link to="/admin/companymanagement" className="nav-link-custom" onClick={toggleMobileNav}>
                                        <div className="nav-link-content">
                                            <FaBuilding className="icon"/> Companies
                                        </div>
                                    </Link>
                                </>
                             )}
                        </Nav>
                    </div>
                    <Nav>
                        <Link className="nav-link-custom-login"
                                  onClick={(e) => {
                                      e.preventDefault();
                                      myAccount();
                                  }}
                                  href="#"
                         to={myAccount}>
                            <div className="nav-link-content">
                                <FaUser className="icon"/>{userEmail}
                            </div>
                        </Link>
                    </Nav>
                </div>

                <Button onClick={toggleMobileNav} className="hamburger-btn">
                    <FontAwesomeIcon icon={faBars} />
                </Button>
            </>
        );
    }
);

export default NavMenu;
