import React, { useState, useEffect, useRef } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Row } from 'react-bootstrap';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import Loading from "../Loading";
import { Line } from "react-chartjs-2";
import './CrashInspection.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import Moment from "react-moment";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const CrashInspection = (props) => {
    const [CrashData, setCrashData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [crashId, setCrashId] = useState(null);
    const [vehicleReference, setVehicleReference] = useState(null);

    const navigate = useNavigate();

    const mapContainer = useRef(null);
    let map = useRef(null);

    const { vehiclereference, crashid } = useParams();

    useEffect(() => {
        document.title = "AGMT | Crash";
        
        mapboxgl.accessToken = 'pk.eyJ1Ijoid2FsZm9yZCIsImEiOiJjanNkYWQ2bHgwdDdkNGFuenZvZGp3MHgwIn0.Tim2j2qijkniUr0_1H497w';
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            zoom: 9
        });

        if (vehiclereference && crashid) {
            setCrashId(crashid);
            setVehicleReference(vehiclereference);
            getCrash(crashid, vehiclereference);
        } else {
            setError(true);
        }
    }, [vehiclereference, crashid]);

    useEffect(() => {
        if (crashId && vehicleReference) {
            getCrash(crashId, vehicleReference);
        }
    }, [crashId, vehicleReference]);


    useEffect(() => {
        if (CrashData && CrashData.crashAccelerometerEventsX && map.current) {
            const { longitude, latitude } = CrashData;

            if (longitude >= -180 && longitude <= 180 && latitude >= -90 && latitude <= 90) {
                map.current.setCenter([longitude, latitude]);

                new mapboxgl.Marker()
                    .setLngLat([longitude, latitude])
                    .addTo(map.current);
            }
        }
    }, [CrashData]);

    const getCrash = (crashId, vehicleReference) => {
        fetch(`/api/Crash/GetCrash?VehicleReference=${vehicleReference}&CrashId=${crashId}`, GetHttpConfig(props.userAccessToken))
            .then((response) => response.json())
            .then((data) => {
                setCrashData(data);
                setLoading(false);
                setError(false);
            })
            .catch((error) => {
                console.error(error);
                setCrashData(null);
                setLoading(false);
                setError(true);
            });
    };

    const getChartData = () => {
        const { crashAccelerometerEventsX, crashAccelerometerEventsY, crashAccelerometerEventsZ } = CrashData;

        const dataLength = crashAccelerometerEventsX.length;

        const labels = Array.from({ length: dataLength }, (_, i) => Math.floor((i / dataLength) * 2000));

        const dataX = crashAccelerometerEventsX.map(val => val / 1000);
        const dataY = crashAccelerometerEventsY.map(val => val / 1000);
        const dataZ = crashAccelerometerEventsZ.map(val => val / 1000);

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Accelerometer data X',
                    data: dataX,
                    fill: false,
                    borderColor: 'rgb(255, 0, 0)',
                    backgroundColor: 'rgb(255, 0, 0)',
                    pointRadius: 0,
                    tension: 0.4,
                    borderWidth: 1
                },
                {
                    label: 'Accelerometer data Y',
                    data: dataY,
                    fill: false,
                    borderColor: 'rgb(0, 255, 0)',
                    backgroundColor: 'rgb(0, 255, 0)',
                    pointRadius: 0,
                    tension: 0.4,
                    borderWidth: 1
                },
                {
                    label: 'Accelerometer data Z',
                    data: dataZ,
                    fill: false,
                    borderColor: 'rgb(0, 0, 255)',
                    backgroundColor: 'rgb(0, 0, 255)', 
                    pointRadius: 0,
                    tension: 0.4,
                    borderWidth: 1
                }
            ],
        };
    };

    const getChartOptions = () => {
        return {
            maintainAspectRatio: false,
            responsive: true,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                tooltip: {
                    enabled: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        label: function(context) {
                            let label = context.dataset.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += context.parsed.y.toFixed(3);
                            }
                            return label;
                        }
                    }
                },
                legend: {
                    display: true
                }
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Time (ms)'
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Acceleration (g)'
                    }
                }
            }
        };
    };

    let content = null;
    if (loading) {
        content = <Loading />;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (!CrashData || !CrashData.crashAccelerometerEventsX || CrashData.crashAccelerometerEventsX.length === 0) {
        content = <p>No crash accelerometer data found.</p>;
    } else {
        const chartData = getChartData();
        const chartOptions = getChartOptions();
        content = <Line data={chartData} options={chartOptions} style={{ width: '100%', height: '70vh' }} />;
    }

    let confidence = (CrashData.confidence * 100).toFixed(0);
    let severity = parseFloat(CrashData.severity).toFixed(2);
    let maxAcceleration = (CrashData.maximumAcceleration / 1000).toFixed(2);

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Crash Inspection</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={3}>
                        <div className="d-flex justify-content-between" style={{ marginTop: '10px' }}>
                            <BackButtonLogoHolder backlink="/crash/crash-list" />
                            {
                                CrashData.isCrash &&
                                <a target="_blank" rel="noopener noreferrer" href={CrashData.theCrashReportLink}>
                                    <Button variant="primary">Download Crash Report</Button>
                                </a>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12} className="chart-col">
                        <div className="chart-wrapper-crash">
                            <div className="tile">
                                <h5>Crash ID</h5>
                                <div className="tile-content">
                                    {crashId}
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Crash Time</h5>
                                <div className="tile-content">
                                    <Moment format="DD/MM/YYYY HH:mm:ss">{CrashData.crashDateTime}</Moment>
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Confidence</h5>
                                <div className="tile-content">
                                    {confidence}%
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Severity</h5>
                                <div className="tile-content">
                                    {severity}
                                </div>
                            </div>
                            <div className="tile">
                                <h5>Altitude</h5>
                                <div className="tile-content">{CrashData.altitude}</div>
                            </div>
                            <div className="tile">
                                <h5>Readings</h5>
                                <div className="tile-content">{CrashData.numberOfAccelerometerReadings}</div>
                            </div>
                            <div className="tile">
                                <h5>Max Acceleration (X/Y)</h5>
                                <div className="tile-content">{maxAcceleration}G</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} lg={6}>
                        <div className="crash-chart-wrapper">
                            {content}
                        </div>
                    </Col>
                    <Col md={6} lg={6}>
                        <div className="crash-map-wrapper">
                            <div ref={mapContainer} style={{ width: '100%', height: '70vh', borderRadius: '15px' }} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CrashInspection;
