import React, {Component} from 'react';
import {Navigate, Route, Routes} from 'react-router';
import Layout from './components/Layout';
import {JourneyListWrapper} from './components/Journey/JourneyList';
import {VehicleList} from './components/Vehicle/VehicleList';
import CrashReports from './components/Crash/CrashReports';
import UserManagement from './components/User/UserManagement';
import AddUser from './components/User/AddUser';
import EditUser from './components/User/EditUser';
import CompanyManagement from './components/Company/CompanyManagement';
import AddCompany from './components/Company/AddCompany';
import EditCompany from './components/Company/EditCompany';
import GroupManagement from './components/Group/GroupManagement';
import AddGroup from './components/Group/AddGroup';
import EditGroup from './components/Group/EditGroup';
import UserGroup from './components/Group/UserGroup';
import VehicleGroup from './components/Group/VehicleGroup';
import AddUserGroup from './components/Group/AddUserGroup';
import AddVehicleGroup from './components/Group/AddVehicleGroup';
import {RequestAccess} from './components/RequestAccess';
import {NotSystemAdmin} from './components/NotSystemAdmin';
import GetHttpConfig from "./components/Helpers/GetHttpConfig";
import {UserManager} from 'oidc-client';
import {CompanyOverview} from "./components/CompanyOverview/CompanyOverview";
import Overview from "./components/DeviceOverview/DeviceOverview";
import CrashInspection from "./components/Crash/CrashInspection";
import Leaderboards from './components/Leaderboards/Leaderboards';
import TamperReports from "./components/Crash/TamperReports";
import LastActivity from "./components/DeviceOverview/LastActivity";
import Loading from "./components/Loading";
import {JourneyListWrapperDebug} from "./components/Journey/JourneyListDEBUG";
import RegisterDevice from "./components/Register/RegisterDevice";
import RegisterDeviceIC6 from "./components/Register/RegisterDeviceIC6";
import {VehicleManagement} from "./components/Vehicle/VehicleManagement";
import DeviceMovementForm from "./components/Register/MoveDevice";
import SwitchCustomer from "./components/Register/SwitchCustomer";
import RegisterAppAccount from "./components/Register/RegisterAppAccount";


export default class App extends Component {

    displayName = App.name

    constructor(props) {
        super(props);

        this.state = {
            authority: null,
            userManager: null,
            userLoggedIn: false,
            userAccessToken: null,
            userEmail: '',
            userManagerChecked: false,
            roles: {},
            rolesChecked: false,
            company: null,
            companyChecked: false,
            hereMapsApikey: null,
            loading: true,
            speedUnit: null
        };
    }

    componentDidMount() {
        fetch('api/Config')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                var settings = {
                    authority: data.authority,
                    client_id: data.clientId,
                    post_logout_redirect_uri: data.postLogOutUri,
                    redirect_uri: data.redirectUri,
                    response_type: 'id_token token',
                    scope: "openid profile " + data.scope,
                    accessTokenExpiringNotificationTime: 4,
                    automaticSilentRenew: true,
                    silent_redirect_uri: window.location.origin + "/silent.html",
                    filterProtocolClaims: true,
                    loadUserInfo: true
                };

                var userManager = new UserManager(settings);
                this.setState({
                    userManager: userManager,
                    authority: data.authority,
                    hereMapsApikey: data.hereMapsApikey,
                    loading: false
                });
                this.getUserIfLoggedIn(userManager);
            })
            .catch(error => {
            });
    }
    
    async getUserIfLoggedIn(userManager) {
        try {
            const user = await userManager.getUser();

            const userState = {
                userManagerChecked: true,
                userLoggedIn: !!user,
                userAccessToken: user ? user.access_token : null,
                userEmail: user ? user.profile.name : "",
                zoomLevel: user ? user.zoomLevel : 100,
                isHeaderRowCollapsed: user ? user.isHeaderRowCollapsed : false,
                dateRangeOffset: user ? user.dateRangeOffset : "1"
            };
            this.setState({
                userManagerChecked: true,
                userAccessToken: userState.userAccessToken,
                userEmail: userState.userEmail,
                userLoggedIn: userState.userLoggedIn,
                isAuthorisedUser: false,
            }, async () => {
                await this.getUserStatus();
            });

            if (!userState.userLoggedIn) {
                await this.state.userManager.signinRedirect();
            }

        } catch (error) {
            console.error(error);
            this.setState({
                userManagerChecked: true,
                isAdmin: false,
                isUser: false,
                userLoggedIn: false,
                isAuthorisedUser: false
            });
        }
    }


    async getUserStatus() {
        try {
            const url = '/api/Report/GetUserStatus';
            const response = await fetch(url, GetHttpConfig(this.state.userAccessToken));

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            this.setState({
                roles: data.roles,
                rolesChecked: true,
                isAuthorisedUser: data.isAuthorisedUser,
                isAdmin: data.isAdmin,
                speedUnit: data.speedUnit
            });
        } catch (error) {
            console.error(error);
            if (error.message.includes('401')) {
                await this.state.userManager.signinSilent();
            }
        }
    }


    render() {
        if (this.state.loading) {
            return (
                <div style={{backgroundColor: 'black'}}>
                    <Loading/>
                </div>
            );
        }

        if (this.state.userLoggedIn === true) {
            return (
                    <Layout
                        userAccessToken={this.state.userAccessToken}
                        userManager={this.state.userManager}
                        roles={this.state.roles}
                        authority={this.state.authority}
                        userLoggedIn={this.state.userLoggedIn}
                        isUser={this.state.isUser}
                        isAuthorisedUser={this.state.isAuthorisedUser}
                        userEmail={this.state.userEmail}
                        isAdmin={this.state.isAdmin}
                    >
                        <Routes>
                            <Route path='/' element={<CompanyOverview userAccessToken={this.state.userAccessToken} roles={this.state.roles} speedUnit={this.state.speedUnit}/>} />
                            <Route path='/vehicle-list' element={<VehicleList userAccessToken={this.state.userAccessToken} roles={this.state.roles} userEmail={this.state.userEmail} />} />
                            <Route path='/vehicle-management' element={this.state.isAdmin ? <VehicleManagement userAccessToken={this.state.userAccessToken} roles={this.state.roles} userEmail={this.state.userEmail} /> : <Navigate to="/" />}/>
                            <Route path='/leaderboards' element={<Leaderboards userAccessToken={this.state.userAccessToken} roles={this.state.roles} speedUnit={this.state.speedUnit}/>} />
                            <Route path='/vehicles/:vehiclereference' element={<JourneyListWrapper userAccessToken={this.state.userAccessToken} roles={this.state.roles} speedUnit={this.state.speedUnit} />} />
                            <Route path='/vehiclesDebug/:vehiclereference' element={<JourneyListWrapperDebug userAccessToken={this.state.userAccessToken} roles={this.state.roles} speedUnit={this.state.speedUnit}/>} />
                            <Route path='/crash/crash-list' element={<CrashReports userAccessToken={this.state.userAccessToken} roles={this.state.roles} />} />
                            <Route path='/tamper-list' element={<TamperReports userAccessToken={this.state.userAccessToken} roles={this.state.roles} />} />
                            <Route path='/crash/:vehiclereference/:crashid' element={<CrashInspection userAccessToken={this.state.userAccessToken} roles={this.state.roles} />} />
                            <Route path='/requestaccess' element={<RequestAccess userAccessToken={this.state.userAccessToken} roles={this.state.roles} />} />
                            <Route path='/notsystemadmin' element={<NotSystemAdmin userAccessToken={this.state.userAccessToken} roles={this.state.roles} />} />
                            <Route path='/company-overview' element={<CompanyOverview userAccessToken={this.state.userAccessToken} roles={this.state.roles} userEmail={this.state.userEmail} />} />
                            <Route path='/deviceOverview/:vehiclereference' element={<Overview userAccessToken={this.state.userAccessToken} roles={this.state.roles} speedUnit={this.state.speedUnit} />} />
                            <Route path='/last-activity' element={<LastActivity userAccessToken={this.state.userAccessToken} roles={this.state.roles} userEmail={this.state.userEmail} />} />
                            <Route path='/switch-customer' element={this.state.isAdmin ? <SwitchCustomer userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/move-device' element={this.state.isAdmin ? <DeviceMovementForm userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/register/registerdevice' element={this.state.isAdmin ? <RegisterDevice userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/register/registerdeviceIC6' element={this.state.isAdmin ? <RegisterDeviceIC6 userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/register/registerappaccount' element={this.state.isAdmin ? <RegisterAppAccount userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/usermanagement' element={this.state.isAdmin ? <UserManagement userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/adduser' element={this.state.isAdmin ? <AddUser userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/edituser' element={this.state.isAdmin ? <EditUser userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/companymanagement' element={this.state.isAdmin ? <CompanyManagement userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/addcompany' element={this.state.isAdmin ? <AddCompany userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/editcompany' element={this.state.isAdmin ? <EditCompany userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/groupmanagement' element={this.state.isAdmin ? <GroupManagement userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/addgroup' element={this.state.isAdmin ? <AddGroup userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/editgroup' element={this.state.isAdmin ? <EditGroup userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/usergroup' element={this.state.isAdmin ? <UserGroup userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/vehiclegroup' element={this.state.isAdmin ? <VehicleGroup userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/addusergroup' element={this.state.isAdmin ? <AddUserGroup userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                            <Route path='/admin/addvehiclegroup' element={this.state.isAdmin ? <AddVehicleGroup userAccessToken={this.state.userAccessToken} roles={this.state.roles} /> : <Navigate to="/" />}/>
                        </Routes>
                    </Layout>
            );
        } else if (this.state.userManager !== null) {
            this.state.userManager.signinRedirect();
        } else {
            return <div style={{backgroundColor: 'black'}}> <p> Loading </p> </div>
        }
    }
}
