import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NavMenu from './NavMenu';
import IsUserInRole from "./Helpers/IsUserInRole";
import './Layout.css';
import Loading from "./Loading";

const Layout = (props) => {
    const [navVisible, setNavVisible] = useState(() => {
        const savedNavVisible = localStorage.getItem('navVisible');
        return savedNavVisible !== null ? JSON.parse(savedNavVisible) : true;
    });

  
    useEffect(() => {
      
    }, []); 

    const toggleNav = () => {
        setNavVisible(prevNavVisible => {
            const newNavVisible = !prevNavVisible;
            localStorage.setItem('navVisible', newNavVisible);
            return newNavVisible;
        });
    };

    const { userManager, userLoggedIn, roles, authority, userAccessToken, userEmail } = props;

    if (userManager !== null) {
        return (
            <Container fluid>
                {userLoggedIn && (
                    <Row>
                        <Col sm={12}>
                            <NavMenu
                                manager={userManager}
                                isAdmin={IsUserInRole(roles, "System Admin")}
                                isUser={IsUserInRole(roles, "UBI Access")}
                                authority={authority}
                                userAccessToken={userAccessToken}
                                userEmail={userEmail}
                                navVisible={navVisible}
                                toggleNav={toggleNav}
                            />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col sm={12}>
                        <div className={`webcontainer1 ${navVisible ? '' : 'full-width'}`}>
                            {props.children}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    } else {
        return <Loading />;
    }
};

export default Layout;
