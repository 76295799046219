import React, { useState } from 'react';
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import './MoveDevice.css';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";

const ErrorModal = ({ show, errorMessage, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const RegisterAppAccount = (props) => {
    const [companies, setCompanies] = useState([]);
    const [formData, setFormData] = useState({
        cognitoUsername: '',
        cognitoPassword: '',
    });
    const [touched, setTouched] = useState({
        cognitoUsername: false,
        cognitoPassword: false,
    });
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleBlur = (e) => {
        setTouched({
            ...touched,
            [e.target.name]: true
        });
    };

    const isValid = (name) => formData[name].trim() !== '' && touched[name];

    const inputClass = (name) => {
        if (!touched[name]) return 'form-control';
        return isValid(name) ? 'form-control is-valid' : 'form-control is-invalid';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify(formData)
        };

        try {
            const response = await fetch('api/Register/RegisterCognitoUser', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            setFormData({
                cognitoUsername: '',
                cognitoPassword: '',
            });
            navigate('/vehicle-management');
        } catch (error) {
            console.error('Error during cognito registration:', error);
            setError(error.message);
            setShowModal(true);
        }
    };

    return (
        <div>
            <ErrorModal show={showModal} errorMessage={error} onHide={() => setShowModal(false)} />

            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={3} lg={3}>
                            <BackButtonLogoHolder backlink="/vehicle-management"/>
                        </Col>
                        <Col md={6} lg={6}>
                            <div className="container mt-5">
                                <form onSubmit={handleSubmit} className="form-container">
                                    <h2>Register Mobile App Account</h2>
                                    <div className="mb-3">
                                        <label htmlFor="accountReference" className="form-label">
                                            New Email:
                                        </label>
                                        <input type="text" className={inputClass('cognitoUsername')}
                                               name="cognitoUsername" id="accountReference"
                                               value={formData.cognitoUsername} onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="vehicleReference" className="form-label">
                                            New Password:
                                        </label>
                                        <input type="text" className={inputClass('cognitoPassword')}
                                               name="cognitoPassword" id="vehicleReference"
                                               value={formData.cognitoPassword} onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Register</button>
                                </form>
                            </div>
                        </Col>
                        <Col md={3} lg={3}></Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default RegisterAppAccount;
