import React, { useState, useEffect } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../User/EditUser.css'

const AddCompany = (props) => {
    const navigate = useNavigate();

    const [NewCompanyData, setNewCompanyData] = useState([]);
    const [name, setName] = useState("");
    const [customerReference, setCustomerReference] = useState("");

    useEffect(() => {
        document.title = "Add Company";
        getAllCompanies();
    }, []);

    const getAllCompanies = async () => {
        try {
            const response = await fetch(`/api/CompanyManagement/GetAllCompanies`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch companies");
            }
            const data = await response.json();
            setNewCompanyData(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCreateCompany = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken },
            body: JSON.stringify({ name, customerReference })
        };
        try {
            const response = await fetch('/api/CompanyManagement/AddCompany', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to add company");
            }
            navigate("/admin/companymanagement");
        } catch (error) {
            console.error(error);
        }
    };

    const handleCompanyNameChange = (event) => {
        setName(event.target.value);
    };

    const handleCustomerReferenceChange = (event) => {
        setCustomerReference(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h5>Add Company</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateCompany} className="form-container">
                                <Form.Group controlId="newName">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        required
                                        placeholder="Enter the Company Name"
                                        onChange={handleCompanyNameChange} />
                                </Form.Group>

                                <Form.Group controlId="customerReference">
                                    <Form.Label>Customer Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={customerReference}
                                        required
                                        placeholder="Enter the Customer Reference"
                                        onChange={handleCustomerReferenceChange} />
                                </Form.Group>

                                <Button variant="primary" type="submit">Create Company</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddCompany;