import React, { useState, useEffect } from 'react';
import './DeviceOverview.css';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import Loading from "../Loading";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button, Col, Container, Row } from "react-bootstrap";
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import GaugeChart from 'react-gauge-chart';
import Moment from "react-moment";
import { getAverageScores, getTotal, mapToLineData } from "./DeviceOverviewUtils";
import { Chart, registerables } from 'chart.js';
import {
    createJourneyCountBar,
    createJourneyDistanceBar,
    createJourneyDurationBar,
    createJourneyPercentagesBarChart,
    createJourneyScoresLineChart,
    createSpeedingAverageLineChart
} from "../Charts/CommonCharts";
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import IsUserInRole from "../Helpers/IsUserInRole";

Chart.register(...registerables);

const Overview = (props) => {
    const { vehiclereference} = useParams();
    const { roles, userAccessToken, speedUnit } = props;
    const vehicleReference = vehiclereference;
    
    const [state, setState] = useState({
        IsAdmin: IsUserInRole(roles, "System Admin"),
        JourneyCountData: [],
        JourneyDistanceData: [],
        JourneyScoresLineData: [],
        JourneyDurationData: [],
        JourneyDurationPercentages: [],
        SpeedingAverage: [],
        VehicleData: [],
        loading: true,
        error: false,
        vehicleReference,
    });

    useEffect(() => {
        if (roles) {
            setState(prevState => ({
                ...prevState,
                IsAdmin: IsUserInRole(roles, "System Admin"),
            }));
        }
    }, [roles]);

    useEffect(() => {
        document.title = "AGMT | " + state.vehicleReference;
        const baseURL = "/api/";
        const deviceAnalysisURL = baseURL + "DeviceAnalysis/";
        const vehicleURL = baseURL + "Vehicle/GetVehicle";
        const params = `?VehicleReference=${state.vehicleReference}`;
        const httpConfig = GetHttpConfig(props.userAccessToken);

        const endpoints = [
            "JourneyCountDevice",
            "JourneyDistanceTotalDevice",
            "DeviceScoresTotal",
            "JourneyDurationTotal",
            "JourneyDurationPercentages",
            "SpeedingAverageTotal"
        ];

        Promise.all([...endpoints.map(endpoint => fetch(deviceAnalysisURL + endpoint + params, httpConfig)),
            fetch(vehicleURL + params, httpConfig)])
            .then(responses => Promise.all(responses.map(res => res.json())))
            .then(([journeyCountData, journeyDistanceData, journeyScoresData, journeyDurationData, journeyDurationPercentages, speedingAverage, vehicleData]) => {
                const journeyScoresLineData = mapToLineData(journeyScoresData);
                setState(prevState => ({
                    ...prevState,
                    JourneyCountData: journeyCountData,
                    JourneyDistanceData: journeyDistanceData,
                    JourneyScoresData: journeyScoresData,
                    JourneyScoresLineData: journeyScoresLineData,
                    JourneyDurationData: journeyDurationData,
                    JourneyDurationPercentages: journeyDurationPercentages,
                    SpeedingAverage: speedingAverage,
                    VehicleData: vehicleData,
                    loading: false,
                    error: false
                }));
            })
            .catch(error => {
                console.error(error);
                setState({
                    JourneyCountData: null,
                    JourneyDistanceData: null,
                    JourneyScoresData: null,
                    JourneyScoresLineData: [],
                    JourneyDurationData: null,
                    VehicleData: null,
                    loading: false,
                    error: true
                });
            });
    }, []);

    const getTotalJourneyCount = () => {
        return getTotal('JourneyCountData', state);
    };

    const getTotalJourneyDistance = () => {
        return getTotal('JourneyDistanceData', state);
    };

    const getTotalJourneyDuration = () => {
        return getTotal('JourneyDurationData', state);
    };

    const { loading, error, vehicleReference: vehicleRef, IsAdmin: IsAdmin} = state;
    let Content = null;
        if (loading || speedUnit === null) {
            Content = <div className="centered-content"><Loading/></div>;
        } else {
            const {journeyCountBar, journeyBarOptions} = createJourneyCountBar(state.JourneyCountData);
            const {
                journeyDistanceBar,
                journeyDistanceOptions
            } = createJourneyDistanceBar(state.JourneyDistanceData, null, speedUnit);
            const {
                journeyScoresLineChart,
                journeyScoresLineChartOptions
            } = createJourneyScoresLineChart(state.JourneyScoresLineData);
            const {
                journeyDurationBar,
                journeyDurationOptions
            } = createJourneyDurationBar(state.JourneyDurationData);
            const {
                journeyPercentagesBarChart,
                journeyPercentagesBarChartOptions
            } = createJourneyPercentagesBarChart(state.JourneyDurationPercentages);
            const {
                speedingLineChart,
                speedingLineChartOptions
            } = createSpeedingAverageLineChart(state.SpeedingAverage);

            const analysisTypeLabels = {
                3: 'Overall',
                4: 'Acceleration',
                5: 'Braking',
                6: 'Cornering',
                7: 'Speeding'
            };

            const averageScores = getAverageScores(state.JourneyScoresLineData);

            let averageScoreContent = (
                <Row>
                    {Object.keys(averageScores).map((analysisTypeId, index) => {
                        if (analysisTypeId !== '3') {
                            return (
                                <Col md={3} lg={3} className="chart-col" key={index}>
                                    <div className="gauge-chart-container">
                                        <div className="gauge-chart">
                                            <GaugeChart
                                                id={`gauge-chart${index}`}
                                                nrOfLevels={15}
                                                percent={averageScores[analysisTypeId] / 100}
                                                textColor={"white"}
                                                needleColor={"orange"}
                                                formatTextValue={(value) => `${Math.round(value)}`}
                                                needleTransitionDuration={3000}
                                                colors={["#FF0000", "#FF7F00", "#FFFF00", "#00FF00"]}
                                            />
                                            <div className="gauge-text">
                                                <h3>{analysisTypeLabels[analysisTypeId]}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            );
                        }
                        return null;
                    })}
                </Row>
            );

            const totalJourneyCount = getTotalJourneyCount();

            const conversionFactor = speedUnit === 0 ? 0.621371 : 1;
            const unitLabel = speedUnit === 0 ? "miles" : "kms";
            const totalJourneyDistance = `${(getTotalJourneyDistance() * conversionFactor).toFixed(1)}`;


            const totalJourneyDuration = getTotalJourneyDuration();
            const averageDistance = totalJourneyCount !== 0 ? (totalJourneyDistance / totalJourneyCount).toFixed(1) : 0;

            let totalSeconds = 0;
            let hours = 0;
            let minutes = 0;
            let averageDuration = `${hours}h ${minutes}m`;

            if (totalJourneyCount !== 0) {
                totalSeconds = totalJourneyDuration / totalJourneyCount;
                hours = Math.floor(totalSeconds / 3600);
                minutes = Math.floor((totalSeconds % 3600) / 60);
                averageDuration = `${hours}h ${minutes}m`;
            }
            
            const vehicleReference = state.VehicleData.vehicleReference;
            const deviceReference = state.VehicleData.deviceReference;
            const createdDateTime = new Date(state.VehicleData.createdDateTime).toLocaleDateString();
            const lastPacketReceived = <Moment fromNow>{state.VehicleData.lastPacketReceived}</Moment>

            Content = (
                <div>
                    <Row>
                        <Col md={12} lg={6} className="chart-col">
                            <div className="chart-wrapper1">
                                <div className="tile">
                                    <h5>Vehicle</h5>
                                    <div className="tile-content">{vehiclereference}</div>
                                </div>
                                <div className="tile">
                                    <h5>Device</h5>
                                    <div className="tile-content">{deviceReference}</div>
                                </div>
                                <div className="tile">
                                    <h5>Date Created</h5>
                                    <div className="tile-content">{createdDateTime}</div>
                                </div>
                                <div className="tile">
                                    <h5>Last seen</h5>
                                    <div className="tile-content">{lastPacketReceived}</div>
                                </div>
                                <div className="tile">
                                    <h5>Journeys</h5>
                                    <div className="tile-content">{totalJourneyCount}</div>
                                </div>
                                <div className="tile">
                                    <h5>Distance</h5>
                                    <div className="tile-content">{totalJourneyDistance} {unitLabel}</div>
                                </div>
                                <div className="tile">
                                    <h5>Avg Distance</h5>
                                    <div className="tile-content">{averageDistance} {unitLabel}</div>
                                </div>
                                <div className="tile">
                                    <h5>Avg Duration</h5>
                                    <div className="tile-content">{averageDuration}</div>
                                </div>
                            </div>

                        </Col>
                        <Col md={12} lg={6} className="chart-col">
                            <div className="chart-wrapper">
                                {averageScoreContent}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={4} className="chart-col">
                            <div className="chart-wrapper">
                                <p className="chart-title">Scores</p>
                                <Line data={journeyScoresLineChart} options={journeyScoresLineChartOptions}/>
                            </div>
                        </Col>
                        <Col md={12} lg={4} className="chart-col">
                            <div className="chart-wrapper">
                                <p className="chart-title">Journey Count</p>
                                <Bar data={journeyCountBar} options={journeyBarOptions} plugins={[ChartDataLabels]}/>
                            </div>
                        </Col>
                        <Col md={12} lg={4} className="chart-col">
                            <div className="chart-wrapper">
                                <p className="chart-title">Duration</p>
                                <Bar data={journeyDurationBar} options={journeyDurationOptions}
                                     plugins={[ChartDataLabels]}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={4} className="chart-col">
                            <div className="chart-wrapper">
                                <p className="chart-title">Journey % Over Speed Limit</p>
                                <Bar data={speedingLineChart} options={speedingLineChartOptions}
                                     plugins={[ChartDataLabels]}/>
                            </div>
                        </Col>
                        <Col md={12} lg={4} className="chart-col">
                            <div className="chart-wrapper">
                                <p className="chart-title">Driving Length</p>
                                <Bar data={journeyPercentagesBarChart} options={journeyPercentagesBarChartOptions}
                                     plugins={[ChartDataLabels]}/>
                            </div>
                        </Col>
                        <Col md={12} lg={4} className="chart-col">
                            <div className="chart-wrapper">
                                <p className="chart-title">Distance (miles)</p>
                                <Bar data={journeyDistanceBar} options={journeyDistanceOptions}
                                     plugins={[ChartDataLabels]}/>
                            </div>
                        </Col>
                    </Row>

                </div>
            );
        }

        return (
            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={12} lg={4}>
                            <div className="d-flex justify-content-left"
                                 style={{marginBottom: '10px', marginTop: '10px'}}>
                                <BackButtonLogoHolder backlink="/vehicle-list"/>
                                 <Link to={`/vehicles/${vehicleReference}`}>
                                    <Button variant="primary" style={{marginRight: '10px', marginLeft: '10px'}}>
                                        Journeys
                                    </Button>
                                </Link>
                                {state.IsAdmin && (
                                    <Link to={`/vehiclesDebug/${vehicleReference}`}>
                                        <Button variant="warning" style={{marginRight: '10px', marginLeft: '0px'}}>
                                            Debug
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        </Col>
                        <Col md={6} lg={4} className="fixed-col2" style={{marginBottom: '10px', marginTop: '10px'}}>
                            <div className="contentvehicle3" style={{ marginTop: '10px' }}>
                                <h4>Vehicle: {vehiclereference}</h4>
                            </div>
                        </Col>

                    </Row>
                    {Content}
                </Container>
            </div>
        );
}

export default Overview;