import React, {Component} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";
import Moment from 'react-moment';
import Loading from "../Loading";
import GetScoreByType from '../Helpers/GetScoreByType';
import './JourneyList.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import { useParams, useLocation} from 'react-router-dom';

export function JourneyListWrapperDebug(props) {
    const params = useParams();
    const location = useLocation();

    return <JourneyListDebug {...props} params={params} location={location} />;
}

/**
 * Displays the journeys a device has made
 */
export class JourneyListDebug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            journeyData: [],
            loading: true,
            error: false,
            startDate: null,
            endDate: null,
            pageSize: 30,
            pageNumber: 1,
            totalResults: 0,
            numberOfPages: 1,
            vehicleReference: this.props.params.vehiclereference,
            showPhantom: false,
            canLoadMore: true,
            selectedJourneyId: null,
            showMarkers: false,
            eventMarkers: [],
            showEventMarkers: true,
            showSpeedingEventMarkers: true,
            speedingEventMarkers: [],
            showSearchContent: false,
            numberValue: 0,
            tempNumberValue: 0,
        }
        document.title = "AGMT | " + this.state.vehicleReference + " Journeys";

        this.handleScroll = this.handleScroll.bind(this);
        this.getJourneys(this.state.pageSize, this.state.pageNumber);
        this.toggleIsPhantom = this.toggleIsPhantom.bind(this);
        

    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        mapboxgl.accessToken = 'pk.eyJ1Ijoid2FsZm9yZCIsImEiOiJjanNkYWQ2bHgwdDdkNGFuenZvZGp3MHgwIn0.Tim2j2qijkniUr0_1H497w';

        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            zoom: 1
        });

        this.map.on('style.load', () => {
            this.map.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
        });

        this.map.addControl(new mapboxgl.FullscreenControl());
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(new mapboxgl.ScaleControl());
    }

    handleInputChange = (e) => {
        this.setState({ tempNumberValue: e.target.value });
    }

    handleButtonClick = () => {
        this.setState(prevState => ({
            numberValue: prevState.tempNumberValue
        }));
        
    }


    toggleIsPhantom = () => {
        this.setState(
            prevState => ({
                showPhantom: !prevState.showPhantom,
                journeyData: []
            }),
            () => {
                this.getJourneys(this.state.pageSize, this.state.pageNumber);
            }
        );
    };


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        if (this.map) {
            this.map.remove();
        }
    }

    handleTableRowClick(journeyId) {
        this.fetchJourneyDetails(journeyId);
        this.setState({activeJourney: journeyId});
    }

    handleScroll() {
        const {canLoadMore, loading} = this.state;
        if (canLoadMore && !loading) {
            const isAtBottom =
                window.innerHeight + document.documentElement.scrollTop ===
                document.documentElement.offsetHeight;
            if (isAtBottom) {
                this.loadMoreJourneys();
            }
        }
    }

    fetchJourneyDetails(journeyId) {
        const urlPath = `/api/Journey/journeyDebug/${journeyId}?minutesToAddOnDebug=${this.state.numberValue}`;

        fetch(urlPath, GetHttpConfig(this.props.userAccessToken))
            .then((response) => response.json())
            .then((data) => {
                const journeyData = this.state.journeyData.map((journey) => {
                    if (journey.journeyId === journeyId) {
                        journey.details = data.details;
                        journey.accelerometerEvents = data.accelerometerEventPositions;
                        journey.speedingEvents = data.speedingEvents;
                        journey.debugpositions = data.debugPositions;
                    }
                    return journey;
                });
                this.setState({journeyData});

                if (data.debugPositions) {
                    this.addJourneyPathToMap(data.debugPositions);
                } else {
                    console.error('No positions data found in the API response');
                }

                if (data.accelerometerEventPositions) {
                    this.addAccelerometerEventsToMap(data.accelerometerEventPositions);
                } else {
                    console.error('No accelerometer event positions data found in the API response');
                }

                if (data.speedingEvents) {
                    this.addSpeedingEventsToMap(data.speedingEvents);
                } else {
                    console.error('No speeding events data found in the API response');
                }
            })
            .catch((error) => {
                console.error('Error fetching journey details:', error);
            });

        this.setState({activeJourney: journeyId});
    }

    addJourneyPathToMap(debugPositions) {
        const lineString = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: debugPositions.map(position => [position.longitude, position.latitude])
            }
        };

        const sourceId = 'journey';

        if (this.map.getSource(sourceId)) {
            this.map.getSource(sourceId).setData({
                type: 'FeatureCollection',
                features: [lineString]
            });
        } else {
            this.map.addSource(sourceId, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [lineString]
                }
            });
        }

        if (this.map.getLayer('journey-line')) {
            this.map.removeLayer('journey-line');
        }

        this.map.addLayer({
            id: 'journey-line',
            type: 'line',
            source: sourceId,
            paint: {
                'line-color': 'rgba(44,127,246,0.85)',
                'line-width': 5
            }
        });

        const existingMarkers = document.querySelectorAll('.debug-marker, .start-marker, .end-marker');
        existingMarkers.forEach(marker => marker.remove());

        if (this.state.showMarkers) {
            debugPositions.forEach(position => {
                const markerElement = document.createElement('div');

                let markerColor;
                if (position.hdop <= 2) {
                    markerColor = '#4CAF50';
                } else if (position.hdop <= 4) {
                    markerColor = '#FFEB3B';
                } else {
                    markerColor = '#F44336';
                }

                markerElement.className = 'debug-marker';
                markerElement.style.backgroundColor = markerColor;

                const marker = new mapboxgl.Marker({element: markerElement})
                    .setLngLat([position.longitude, position.latitude])
                    .addTo(this.map);

                const timestamp = new Date(position.timestamp).toLocaleString();
                const tooltip = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false,
                    offset: 25
                }).setHTML(`
    <div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
        <p style="margin: 0"><strong>Timestamp:</strong> ${timestamp} </p>
<p style="margin: 0"><strong>Speed:</strong> ${(position.speed * (this.props.speedUnit === 0 ? 1 : 1.60934)).toFixed(0)} ${this.props.speedUnit === 0 ? 'mph' : 'kmh'}</p>
        <p style="margin: 0"><strong>HDOP:</strong> ${position.hdop.toFixed(1)}</p>
    </div>
`);

                marker.getElement().addEventListener('mouseenter', () => {
                    marker.setPopup(tooltip).togglePopup();
                });

                marker.getElement().addEventListener('mouseleave', () => {
                    marker.togglePopup();
                });
            });
        }

        debugPositions.forEach((position, index) => {

            if (index === 0) {
                const startMarkerElement = document.createElement('div');
                startMarkerElement.className = 'start-marker';

                new mapboxgl.Marker({element: startMarkerElement})
                    .setLngLat([position.longitude, position.latitude])
                    .addTo(this.map);
            }

            if (index === debugPositions.length - 1) {
                const endMarkerElement = document.createElement('div');
                endMarkerElement.className = 'end-marker';

                new mapboxgl.Marker({element: endMarkerElement})
                    .setLngLat([position.longitude, position.latitude])
                    .addTo(this.map);
            }
        });

        const coordinates = debugPositions.map(position => [position.longitude, position.latitude]);
        const bounds = coordinates.reduce(
            (acc, coord) => acc.extend(coord),
            new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
        );

        const screenWidth = window.innerWidth;

        if (screenWidth <= 1000) { // Adjust the breakpoint as needed
            this.map.fitBounds(bounds, {
                padding: 10,
                maxZoom: 11,
            });
        } else {
            this.map.fitBounds(bounds, {
                padding: 200,
                maxZoom: 15,
            });
        }
    }

    addAccelerometerEventsToMap(accelerometerEventPositions) {
        this.state.eventMarkers.forEach(marker => marker.remove());

        const getEventTypeDescription = (eventType) => {
            switch (eventType) {
                case 1:
                    return 'Acceleration';
                case 2:
                    return 'Braking';
                case 3:
                    return 'Cornering';
                case 4:
                    return 'Tamper'
                case 5:
                    return 'Maneuvering'
                default:
                    return 'Unknown';
            }
        };

        const getLevelDescription = (level) => {
            switch (level) {
                case 0:
                    return 'Minor';
                case 1:
                    return 'Medium';
                case 2:
                    return 'Harsh';
                case 3:
                    return 'Extreme';
                default:
                    return 'Unknown';
            }
        };

        accelerometerEventPositions.forEach((position) => {
            const {latitude, longitude, accelerometerEvents} = position;

            if (this.state.showEventMarkers) {
                accelerometerEvents.forEach((event) => {
                    const {level, eventType} = event;

                    const markerElement = document.createElement('div');
                    let markerText = '';

                    if (eventType === 1) {
                        markerText = `A${level + 1}`;
                    } else if (eventType === 2) {
                        markerText = `B${level + 1}`;
                    } else if (eventType === 3) {
                        markerText = `C${level + 1}`;
                    } else if (eventType === 4) {
                        markerText = `T`;
                    } else if (eventType === 5) {
                        markerText = `M${level + 1}`;
                    }

                    let markerColor = '';
                    let textColor = ''
                    if (level === 0) {
                        markerColor = 'yellow';
                        textColor = 'black';
                    } else if (level === 1) {
                        markerColor = 'orange';
                        textColor = 'black';
                    } else if (level === 2) {
                        markerColor = 'darkorange';
                        textColor = 'white';
                    } else if (level === 3) {
                        markerColor = 'red';
                        textColor = 'white';
                    }

                    const levelDescription = getLevelDescription(level);

                    markerElement.className = `event-marker event-marker-${markerColor}`;
                    markerElement.style.color = textColor
                    markerElement.innerText = markerText;
                    const marker = new mapboxgl.Marker({element: markerElement})
                        .setLngLat([longitude, latitude])
                        .addTo(this.map);
                    
                    

                    let tooltipContent = `
<div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
    <p style="margin: 0">${new Date(event.eventDateTime).toLocaleTimeString()}</p>
    <p style="margin: 0"><strong>Event Type:</strong> ${getEventTypeDescription(eventType)}</p>
    <p style="margin: 0"><strong>Level:</strong> ${level + 1} - ${levelDescription}</p>
</div>


`;


                    const tooltip = new mapboxgl.Popup({closeButton: false, closeOnClick: false})
                        .setHTML(tooltipContent);
                    marker.setPopup(tooltip);

                    markerElement.addEventListener('mouseenter', () => {
                        marker.setPopup(tooltip);
                        tooltip.addTo(this.map);
                    });

                    markerElement.addEventListener('mouseleave', () => {
                        tooltip.remove();
                    });

                    this.setState(prevState => ({
                        eventMarkers: [...prevState.eventMarkers, marker],
                    }));
                });
            }
        });
    }

    addSpeedingEventsToMap(speedingEvents) {
        this.clearSpeedingEventMarkers();

        if (this.state.showSpeedingEventMarkers) {
            speedingEvents.forEach((speedingEvent) => {
                const speedConversionFactor = this.props.speedUnit === 0 ? 2.23694 : 3.6;
                const roundingFactor = this.props.speedUnit === 0 ? 5 : 10;
                const speed = speedingEvent.speed * speedConversionFactor;
                const speedLimit = Math.round((speedingEvent.speedLimit * speedConversionFactor) / roundingFactor) * roundingFactor;


                if (this.props.speedUnit === 0 && speedLimit === 70 && (speed >= 70 && speed <= 82)) {
                    return;
                }

                if (this.props.speedUnit === 1 && speedLimit === 110 && (speed >= 110 && speed <= 132)) {
                    return;
                }

                let textColor = ''
                let markerColor = '';
                if (speed / speedLimit > 1.00 && speed / speedLimit <= 1.15) {
                    markerColor = 'yellow';
                    textColor = 'black';
                } else if (speed / speedLimit > 1.15 && speed / speedLimit <= 1.20) {
                    markerColor = 'orange';
                    textColor = 'black';
                } else if (speed / speedLimit > 1.20 && speed / speedLimit <= 1.25) {
                    markerColor = 'darkorange';
                    textColor = 'white';
                } else if (speed / speedLimit > 1.25) {
                    markerColor = 'red';
                    textColor = 'white'

                }


                var el = document.createElement('div');
                el.className = `event-marker event-marker-${markerColor}`;
                el.style.color = textColor
                el.innerText = `${speed.toFixed(0)}`;
                el.style.width = '25px';
                el.style.height = '25px';

                const {latitude, longitude} = speedingEvent.position;

                const marker = new mapboxgl.Marker(el)
                    .setLngLat([longitude, latitude])
                    .addTo(this.map);

                const eventTime = new Date(speedingEvent.eventDateTime).toLocaleTimeString('en-GB', {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                });

                const tooltipContent = `
<div style="color: #333; font-size: 14px; padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 4px; text-align: center;">
    <p style="margin: 0"><strong>Event Time:</strong> ${eventTime}</p>
<p style="margin: 0"><strong>Speed:</strong> ${speed.toFixed(1)} ${this.props.speedUnit === 0 ? 'mph' : 'kmh'}</p>
<p style="margin: 0"><strong>Speed Limit:</strong> ${speedLimit.toFixed(0)} ${this.props.speedUnit === 0 ? 'mph' : 'kmh'}</p>
</div>
`;



                const tooltip = new mapboxgl.Popup({closeButton: false, closeOnClick: false})
                    .setHTML(tooltipContent);
                marker.setPopup(tooltip);

                marker.getElement().addEventListener('mouseenter', () => {
                    marker.setPopup(tooltip);
                    tooltip.addTo(this.map);
                });

                marker.getElement().addEventListener('mouseleave', () => {
                    tooltip.remove();
                });

                this.setState(prevState => ({
                    speedingEventMarkers: [...prevState.speedingEventMarkers, marker]
                }));
            });
        }
    }

    clearSpeedingEventMarkers() {
        const {speedingEventMarkers} = this.state;
        if (speedingEventMarkers && speedingEventMarkers.length > 0) {
            speedingEventMarkers.forEach(marker => marker.remove());
        }
        this.setState({speedingEventMarkers: []});
    }

    loadMoreJourneys() {
        const {pageNumber, numberOfPages, canLoadMore} = this.state;
        if (canLoadMore && pageNumber < numberOfPages) {
            const nextPageNumber = pageNumber + 1;
            this.setState({pageNumber: nextPageNumber}, () => {
                this.getJourneys(this.state.pageSize, this.state.pageNumber);
            });
        }
    }

    getJourneys(pageSize, pageNumber) {
        return new Promise((resolve, reject) => {
            let urlPath = `/api/Journey/journeys?VehicleReference=${this.state.vehicleReference}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&ShowPhantom=${this.state.showPhantom}`;
            if (this.state.startDate && this.state.endDate) {
                urlPath = urlPath + `&StartDate=${this.state.startDate.toISOString()}&EndDate=${this.state.endDate.toISOString()}`;
            }
            fetch(urlPath, GetHttpConfig(this.props.userAccessToken))
                .then(response => response.json())
                .then(data => {
                    const journeyData = data.results;
                    const loadedJourneyCount = this.state.journeyData.length + journeyData.length;
                    const canLoadMore = loadedJourneyCount < data.pageDetail.totalResults;

                    this.setState(prevState => ({
                        journeyData: [...prevState.journeyData, ...journeyData],
                        loading: false,
                        error: false,
                        totalResults: data.pageDetail.totalResults,
                        numberOfPages: data.pageDetail.numberOfPages,
                        pageNumber: data.pageDetail.pageNumber,
                        canLoadMore: canLoadMore
                    }), () => {
                        if (this.state.journeyData.length > 0) {
                            this.fetchJourneyDetails(this.state.journeyData[0].journeyId);
                            this.setState({activeJourney: this.state.journeyData[0].journeyId});
                        }
                    });

                    resolve();
                })
                .catch(error => {
                    this.setState({
                        journeyData: null,
                        loading: false,
                        error: true,
                        totalResults: 0,
                        numberOfPages: 1,
                        pageNumber: 1
                    });

                    reject(error);
                });
        });
    }


    searchForJourneys() {
        this.setState({
            loading: true,
            journeyData: [],
            totalResults: null,
            numberOfPages: null,
            pageNumber: 1,
            canLoadMore: true
        });
        this.getJourneys(this.state.pageSize, this.state.pageNumber);
    }

    clearSearch() {
        this.setState({
            startDate: null,
            endDate: null,
            loading: true,
            journeyData: [],
            totalResults: null,
            numberOfPages: null,
            pageNumber: 1,
            canLoadMore: true
        });
        this.getJourneys(this.state.pageSize, this.state.pageNumber);
    }

    toggleSearchContent = () => {
        this.setState(prevState => ({
            showSearchContent: !prevState.showSearchContent
        }));
    }
    toggleGPSMarkers = () => {
        const {showMarkers, activeJourney, journeyData} = this.state;

        this.setState({showMarkers: !showMarkers}, () => {
            const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);

            const existingMarkers = document.querySelectorAll('.debug-marker');
            existingMarkers.forEach(marker => marker.remove());

            if (this.state.showMarkers && activeJourneyData) {
                this.addJourneyPathToMap(activeJourneyData.debugpositions);
            }
        });
    };
    toggleEventMarkers = () => {
        const {showEventMarkers, eventMarkers} = this.state;
        this.setState({showEventMarkers: !showEventMarkers}, () => {
            eventMarkers.forEach(marker => marker.remove());

            this.setState({eventMarkers: []});

            if (this.state.showEventMarkers) {
                const {journeyData, activeJourney} = this.state;
                const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
                if (activeJourneyData) {
                    this.addAccelerometerEventsToMap(activeJourneyData.accelerometerEvents);
                }
            }
        });
    };

    toggleSpeedingEventMarkers = () => {
        const {showSpeedingEventMarkers, speedingEventMarkers} = this.state;
        this.setState({showSpeedingEventMarkers: !showSpeedingEventMarkers}, () => {
            speedingEventMarkers.forEach(marker => marker.remove());

            this.setState({speedingEventMarkers: []});

            if (this.state.showSpeedingEventMarkers) {
                const {journeyData, activeJourney} = this.state;
                const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
                if (activeJourneyData) {
                    this.addSpeedingEventsToMap(activeJourneyData.speedingEvents);
                }
            }
        });
    };

    render() {
        const {journeyData, loading, error, totalResults} = this.state;
        const loadedItems = journeyData.length;
        const {activeJourney} = this.state;
        const {showSearchContent} = this.state;

        const statusMapping = {
            1: 'Tamper',
            2: 'Start cal',
            3: 'End cal',
            4: 'Phantom'
        };
        
        journeyData.forEach(function (journey) {
            if (journey.journeyScores.length === 0) {
                for (var x = 1; x < 9; x++) {
                    journey.journeyScores.push({scoreType: x, score: 0});
                }
            }
        });
        let journeyScoresContent = null;
        const desiredScoreTypes = [1, 2, 3, 4];
        if (activeJourney) {
            const activeJourneyData = journeyData.find(journey => journey.journeyId === activeJourney);
            if (activeJourneyData) {
                const journeyScores = activeJourneyData.journeyScores;

                journeyScoresContent = (
                    <div>
                        <div style={{
                            padding: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: 'white'
                        }}>
                            {journeyScores
                                .filter(score => desiredScoreTypes.includes(score.scoreType))
                                .map(score => {
                                    let scoreColor;
                                    if (score.score >= 80) {
                                        scoreColor = 'green';
                                    } else if (score.score >= 50) {
                                        scoreColor = 'orange';
                                    } else {
                                        scoreColor = 'red';
                                    }

                                    let scoreTypeName;
                                    switch (score.scoreType) {
                                        case 1:
                                            scoreTypeName = 'Acceleration';
                                            break;
                                        case 2:
                                            scoreTypeName = 'Braking';
                                            break;
                                        case 3:
                                            scoreTypeName = 'Cornering';
                                            break;
                                        case 4:
                                            scoreTypeName = 'Speeding';
                                            break;
                                        case 5:
                                            scoreTypeName = 'Manoeuvre';
                                            break;
                                        default:
                                            scoreTypeName = '';
                                    }

                                    return (
                                        <p key={score.scoreType}
                                           style={{margin: '0', flexBasis: '25%', textAlign: 'center', fontStyle: 'b'}}>
                                            <span style={{
                                                color: scoreColor,
                                                fontSize: 'x-large',
                                                fontWeight: 'bold'
                                            }}>{score.score.toFixed(2)}</span>
                                            <br/> {scoreTypeName}
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                );


            }
        }

        let content = null;

        if (this.state.loading) {
            content = <Loading/>;
        } else {
            if (this.state.error) {
                content = <p>There was an error.</p>;
            } else {
                if (this.state.journeyData.length === 0) {
                    content = <p>No journeys reported for unit.</p>;
                } else {

                    const filteredJourneys = this.state.showPhantom
                        ? journeyData
                        : journeyData.filter(journey => journey.distance !== 0)


                    content = (
                        <div className="table-responsive">
                            <table className="table table-striped table-dark">
                                <thead>
                                <tr>
                                    <th>When</th>
                                    <th>Date</th>
                                    <th>Start</th>
                                    <th>End</th>
                                    <th>Received DateTime</th>
                                    <th>When Received</th>
                                    <th>Duration</th>
                                    <th>Distance</th>
                                    <th>Score</th>
                                    <th>Status</th>
                                    <th>Telemetry %</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredJourneys.map(function (journey) {
                                    return (
                                        <tr
                                            key={journey.journeyId}
                                            onClick={() => this.handleTableRowClick(journey.journeyId)}
                                            className={this.state.activeJourney === journey.journeyId ? 'active' : ''}
                                        >
                                            <td>
                                                <Moment fromNow>{journey.startDateTime}</Moment>
                                            </td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">{journey.startDateTime}</Moment>
                                            </td>
                                            <td>
                                                <Moment format="HH:mm:ss">{journey.startDateTime}</Moment>
                                            </td>
                                            <td>
                                                <Moment format="HH:mm:ss">{journey.endDateTime}</Moment>
                                            </td>
                                            <td>
                                                <Moment format="DD-MM-YYYY HH:mm:ss">{journey.receivedDateTime}</Moment>
                                            </td>
                                            <td>
                                                <Moment fromNow>{journey.receivedDateTime}</Moment>
                                            </td>
                                            <td>{journey.duration.toFixed(0)} mins</td>
                                            <td>
                                                {this.props.speedUnit === 0
                                                    ? `${(journey.distance * 0.621371).toFixed(2)} miles`
                                                    : `${journey.distance.toFixed(2)} kms`}
                                            </td>
                                            <td>{Math.round(GetScoreByType(5, journey.journeyScores))} / 100</td>
                                            <td>
                                                {journey.journeyStatus
                                                    ? journey.journeyStatus
                                                        .map(statusObj => statusMapping[statusObj.status] || statusObj.status)
                                                        .join(', ')
                                                    : 'N/A'}
                                            </td>
                                            <td> {journey.telemetryPercentage}</td>
                                        </tr>
                                    );
                                }.bind(this))
                                }
                                </tbody>
                            </table>
                        </div>
                    );
                }
            }
        }
        return (
            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12} className="fixed-col2">
                            <div className="contentvehicle3" style={{gridColumn: '1 / -1', textAlign: 'center'}}>
                                <h5>DEBUG!!! Vehicle: {this.props.params.vehiclereference}</h5>
                            </div>
                        </Col>
                        <Col md={2} lg={2} className="backbuttonlogoholder-journeylist">
                            <BackButtonLogoHolder backlink={'/deviceOverview/' + this.state.vehicleReference}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} lg={8}>
                            <div className="contentjourneylist">
                                <h4>Journeys</h4>
                                <Button variant={"secondary"} onClick={this.toggleSearchContent}
                                        style={{marginBottom: '10px'}}>
                                    {showSearchContent ? 'Hide Search' : 'Show Search'}
                                </Button>
                                <Button variant={this.state.showPhantom ? "success" : "secondary"}
                                        style={{marginLeft: '10px', flex: '1', marginBottom: '10px'}}
                                        onClick={this.toggleIsPhantom}>
                                    {this.state.showPhantom ? 'Include Invalid Journeys' : 'Include Invalid Journeys'}
                                </Button>
                                <input
                                    type="number"
                                    value={this.state.tempNumberValue}
                                    onChange={this.handleInputChange}
                                />
                                <button onClick={this.handleButtonClick}>Update Number</button>
                                <p>Current number: {this.state.numberValue}</p>
                                <p>Current number: {this.state.minutesToAdd}</p>
                                {showSearchContent && (
                                    <div className="contentsearch">
                                        <h4>Search</h4>
                                        <Form.Group>
                                            <p>Start Date:</p>
                                            <DatePicker
                                                selected={this.state.startDate}
                                                onChange={(date) => this.setState({startDate: date})}
                                                selectsStart
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                isClearable
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />

                                            <p>End Date:</p>
                                            <DatePicker
                                                selected={this.state.endDate}
                                                onChange={(date) => this.setState({endDate: date})}
                                                selectsEnd
                                                startDate={this.state.startDate}
                                                endDate={this.state.endDate}
                                                minDate={this.state.startDate}
                                                isClearable
                                                showTimeSelect
                                                dateFormat="Pp"
                                            />
                                        </Form.Group>

                                        <Button variant="primary" onClick={() => this.searchForJourneys()}>
                                            Search
                                        </Button>{' '}
                                        <Button variant="secondary" onClick={() => this.clearSearch()}>
                                            Clear
                                        </Button>
                                    </div>
                                )
                                }
                                {content}
                                {this.state.canLoadMore &&
                                    <Loading/>
                                }
                            </div>
                        </Col>
                        <Col md={4} lg={4} className="fixed-col-journeylist">

                            {journeyScoresContent && (
                                <div style={{margin: '0px 0'}}>
                                    {journeyScoresContent}
                                </div>
                            )}
                            <div className="fixed-col">
                                <div style={{display: 'flex', marginBottom: '0px'}}>
                                    <Button style={{flex: '1'}} variant={this.state.showMarkers ? "success" : "danger"}
                                            onClick={this.toggleGPSMarkers}>
                                        {this.state.showMarkers ? 'GPS' : 'GPS'}
                                    </Button>
                                    <Button style={{flex: '1'}}
                                            variant={this.state.showEventMarkers ? "success" : "danger"}
                                            onClick={this.toggleEventMarkers}>
                                        {this.state.showEventMarkers ? 'Events' : 'Events'}
                                    </Button>
                                    <Button style={{flex: '1'}}
                                            variant={this.state.showSpeedingEventMarkers ? "success" : "danger"}
                                            onClick={this.toggleSpeedingEventMarkers}>
                                        {this.state.showSpeedingEventMarkers ? 'Speeding' : 'Speeding'}
                                    </Button>
                                </div>


                                <div id="mapContainer" ref={el => (this.mapContainer = el)}
                                     style={{width: '100%', height: '100%'}}></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}