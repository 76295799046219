import {getPastTwelveMonths} from "../DeviceOverview/DeviceOverviewUtils";


export function createActiveDevicesBar(ActiveDevicesData) {
    const labels = getPastTwelveMonths();
    const data = [
        ActiveDevicesData?.month11 || 0,
        ActiveDevicesData?.month10 || 0,
        ActiveDevicesData?.month9 || 0,
        ActiveDevicesData?.month8 || 0,
        ActiveDevicesData?.month7 || 0,
        ActiveDevicesData?.month6 || 0,
        ActiveDevicesData?.month5 || 0,
        ActiveDevicesData?.month4 || 0,
        ActiveDevicesData?.month3 || 0,
        ActiveDevicesData?.month2 || 0,
        ActiveDevicesData?.month1 || 0,
        ActiveDevicesData?.month0 || 0
    ];

    const allValuesAreZero = data.every(val => val === 0);
    const maxYValue = Math.max(...data) * 1.2;

    const activeDevicesBar = {
        labels,
        datasets: [
            {
                label: 'Active Devices Data',
                data,
                backgroundColor: 'rgba(153,102,255,1)',
                borderColor: 'rgba(153,102,255,1)',
                hoverBackgroundColor: 'rgba(153,102,255,1)',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }
        ]
    };

    let delayed;

    const activeDevicesOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                clamp: 'true',
                formatter: function(value) {
                    if (value === 0) {
                        return '';
                    }
                    return Math.round(value);
                }
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        animation: {
            delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 30 + context.datasetIndex * 10;
                }
                return delay;
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: allValuesAreZero ? 1 : maxYValue,
                display: false
            },
            x: {
                ticks: {
                    color: 'white',
                },
            }
        }
    };

    return { activeDevicesBar, activeDevicesOptions };
}

export function createJourneyCountBar(JourneyCountData, ActiveDevicesData = null) {
    const labels = getPastTwelveMonths();
    let data = [];

    if(ActiveDevicesData === null){
        for (let i = 11; i >= 0; i--) {
            let averageJourneyCount = JourneyCountData?.[`month${i}`];
            data.push(averageJourneyCount);
        }
    }
    else {
        for (let i = 11; i >= 0; i--) {
            let journeyCount = JourneyCountData?.[`month${i}`];
            let activeDevices = ActiveDevicesData[`month${i}`];
            let averageJourneyCount = activeDevices > 0 ? journeyCount / activeDevices : 0;
            data.push(averageJourneyCount);
        }
    }

    const allValuesAreZero = data.every(val => val === 0);
    const maxYValue = Math.max(...data) * 1.2;

    const journeyCountBar = {
        labels,
        datasets: [
            {
                label: 'Journey Count Data',
                data,
                backgroundColor: '#0f8dff',
                borderColor: '#0f8dff',
                borderWidth: 1,
                hoverBackgroundColor: '#0f8dff',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }
        ]
    };
    const journeyBarOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                clamp: 'true',
                formatter: function(value) {
                    if (value === 0) {
                        return '';
                    }
                    return Math.round(value);
                }
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        scales: {
            y: {
                max: allValuesAreZero ? 1 : maxYValue,
                display: false,
            },
            x : {
                ticks: {
                    font: {
                        size: 12
                    },
                    color: 'white',
                }
            }

        }
    };

    return { journeyCountBar, journeyBarOptions };
}

export function createJourneyDurationTotalBar(TotalDurationMonth, ActiveDevicesData = null) {
    const labels = getPastTwelveMonths();
    let data = [];

    if (ActiveDevicesData === null){
        for (let i = 11; i >= 0; i--) {
            let totalDuration = TotalDurationMonth?.[`month${i}`];
            data.push(totalDuration);
        }
    }
    else {
        for (let i = 11; i >= 0; i--) {
            let totalDuration = TotalDurationMonth?.[`month${i}`];
            let activeDevices = ActiveDevicesData[`month${i}`];
            let averageDuration = activeDevices > 0 ? (totalDuration / activeDevices) : 0;
            data.push(averageDuration);
        }
    }

    const allValuesAreZero = data.every(val => val === 0);
    const maxYValue = Math.max(...data) * 1.2;

    const journeyDurationTotalBar = {
        labels,
        datasets: [
            {
                label: 'Journey Count Data',
                data,
                backgroundColor: '#05b053',
                borderWidth: 1,
                hoverBackgroundColor: '#05b053',
                hoverBorderColor: 'rgba(75,192,192,1)',
            }
        ]
    };
    const journeyDurationBarOptions = {
        plugins: {
            datalabels: {
                display: true,
                color: 'white',
                align: 'end',
                anchor: 'end',
                formatter: function (value) {
                    if (value === 0) {
                        return '';
                    }
                    else if (value >= 86400) {
                        const days = Math.floor(value / 86400)
                        return `${days}d`;
                    }
                    else if (value >= 3600) {
                        const hours = Math.floor(value / 3600);
                        return `${hours}h`;
                    } else if (value < 3600) {
                        const minutes = Math.floor(value / 60);
                        return `${minutes}m`;
                    }
                    return Math.round(value);
                },
                font: {}
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
        },
        scales: {
            y: {
                max: allValuesAreZero ? 1 : maxYValue,
                display: false,
            },
            x : {
                ticks: {
                    font: {
                        size: 12
                    },
                    color: 'white',
                }
            }

        }
    };

    return { journeyDurationTotalBar, journeyDurationBarOptions };
}