import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

const AddGroup = (props) => {
    const [groupName, setGroupName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Add Group";
    }, []);

    const handleCreateGroup = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
            body: JSON.stringify({groupName})
        };

        try {
            const response = await fetch('/api/GroupManagement/AddGroup', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to add group");
            }
            navigate("/admin/groupmanagement");
        } catch (error) {
            console.error(error);
            // Handle error here
        }
    };

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{textAlign: 'center'}}>
                            <h5>Add Group</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateGroup} className="form-container">
                                <Form.Group controlId="newGroupName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={groupName}
                                        required
                                        placeholder="Enter a Group Name"
                                        onChange={handleGroupNameChange}
                                    />
                                    <br/><br/>
                                    <Button variant="primary" type="submit">Create Group</Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddGroup;
