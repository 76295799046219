import React, { useState, useEffect } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Col, Container, Row } from 'react-bootstrap';
import Loading from "../Loading";
import './Leaderboards.css';
import JourneyIcon from "../images/destination.png"
import MeasureDistance from "../images/measure-distance.png"
import Duration from "../images/chronometer.png"
import SpeedCamera from "../images/speed-camera.png"
import Quick from "../images/quick.png"
import LongDistance from "../images/long-distance.png"
import Route from "../images/route.png"
import Scores from "../images/speedometer.png"
import TimePassing from "../images/time-passing.png"


const useFetchData = (monthLimit, analysisId, userAccessToken) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchLeaderboardData = async () => {
            try {
                const response = await fetch(`/api/DeviceAnalysis/GetLeaderboardData?MonthLimit=${monthLimit}&AnalysisId=${analysisId}&Limit=100`, GetHttpConfig(userAccessToken));
                const jsonData = await response.json();
                setData(jsonData);
                setLoading(false);
                setError(false);
            } catch (error) {
                console.error(error);
                setData(null);
                setLoading(false);
                setError(true);
            }
        };
        fetchLeaderboardData();
    }, [monthLimit, analysisId, userAccessToken]);

    return { data, loading, error, setLoading};
};

const Leaderboards = ({ userAccessToken, speedUnit }) => {
    document.title = "AGMT | Leaderboard";
    const [monthLimit, setMonthLimit] = useState(1);
    const [analysisId, setAnalysisId] = useState(1);
    const { data, loading, error, setLoading } = useFetchData(monthLimit, analysisId, userAccessToken);

    const [hoverIndex, setHoverIndex] = useState(-1);

    const formatLeaderboardValue = (item, analysisId) => {
        const unitLabel = speedUnit === 0 ? "miles" : "kms";
        const conversionFactor = speedUnit === 0 ? 1 : 1.60934;

        switch (analysisId) {
            case 1:
                return `${item.leaderboardValue} journeys`;
            case 2:
            case 16:
            case 17: {
                const distanceValue = speedUnit === 0
                    ? Math.round(item.leaderboardValue / 1.60934)
                    : Math.round(item.leaderboardValue * conversionFactor);
                return `${distanceValue} ${unitLabel}`;
            }
            case 8:
            case 15:
                return `${Math.floor(item.leaderboardValue / 3600)}h ${Math.floor((item.leaderboardValue % 3600) / 60)}m`;
            case 3:
                return `${item.leaderboardValue.toFixed(1)}`;
            case 13: {
                const speedValue = speedUnit === 0
                    ? Math.round(item.leaderboardValue * 2.23694)
                    : Math.round(item.leaderboardValue * 3.6);
                const speedUnitLabel = unitLabel === 'miles' ? 'mph' : 'kph';
                return `${speedValue} ${speedUnitLabel}`;
            }
            case 14:
                return `${((item.leaderboardValue * 100) - 100).toFixed(1)}%`;
            default:
                return item.leaderboardValue;
        }
    };


    const handleMonthLimitChange = (newLimit) => {
        setLoading(true);
        setMonthLimit(newLimit);
    };

    const handleAnalysisIdChange = (newId) => {
        setLoading(true);
        setAnalysisId(newId);
    };

    
    const renderContent = () => {
        if (loading || speedUnit === null) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh'
                }}>
                    <Loading />
                </div>
            );
        }

        if (error) {
            return <p>There was an error.</p>;
        }

        if (data.length === 0) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh'
                }}>
                    <p style={{
                        color: 'white',
                        fontSize: '2em'
                    }}>No Data</p>
                </div>
            );
        }

        return (
            <div>
                <div className="content-vehicle-list">
                    <div className="chart-title">
                        <table className="table table-striped table-dark">
                            <thead>
                            <tr>
                                <th>Vehicle</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, index) => (
                                <tr key={index} onClick={() => window.location.href = '/deviceOverview/' + item.vehicleReference}
                                    onMouseOver={() => setHoverIndex(index)}
                                    onMouseOut={() => setHoverIndex(-1)}
                                    style={index === hoverIndex ? { backgroundColor: "#094f7e", cursor: 'pointer' } : {}}>
                                    <td>{item.vehicleReference}</td>
                                    <td>{formatLeaderboardValue(item, analysisId)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Leaderboards</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                <Col md={3} lg={3} className="fixed-leaderboard">
                    <div className="leaderboard-date">
                        <div className="leaderboard-date2">
                        <div className="flex align-items-center" style={{marginTop: '0px' }}>
                            <div className="contentvehicle3">
                                <h5>Leaderboard Category</h5>
                            </div>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 1 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(1)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={JourneyIcon} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Total Journeys</span>
                                </div>
                            </Button>

                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 2 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(2)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={MeasureDistance} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Total Distance</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 3 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(3)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={Scores} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Scores</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 8 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(8)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={Duration} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Total Duration</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 13 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(13)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={SpeedCamera} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Max Speed</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 14 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(14)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={Quick} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>% Speeding</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 15 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(15)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={TimePassing} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Max Duration</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 16 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(16)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={LongDistance} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Max Distance</span>
                                </div>
                            </Button>
                            <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                    variant={analysisId === 17 ? 'primary' : 'secondary'}
                                    onClick={() => handleAnalysisIdChange(17)}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                    <img src={Route} alt="journeys icon" style={{ marginRight: '5px', height: '40px' }} />
                                    <span>Average Distance</span>
                                </div>
                            </Button>
                        </div>
                        </div>
                    </div>
                </Col>
                <Col md={6} lg={6}>
                    <br></br>
                    {renderContent()}
                </Col>
                    <Col md={3} lg={3} className="fixed-leaderboard">
                        <div className="leaderboard-date2">
                            <div className="contentvehicle3">
                                <h5>Date</h5>
                            </div>
                            <div className="flex align-items-center" style={{marginTop: '0px' }}>
                                <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                        variant={monthLimit === 1 ? 'primary' : 'secondary'}
                                        onClick={() => handleMonthLimitChange(1)}>
                                    Current Month
                                </Button>
                                <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                        variant={monthLimit === 3 ? 'primary' : 'secondary'}
                                        onClick={() => handleMonthLimitChange(3)}>
                                    3 Months
                                </Button>
                                <Button style={{ flex: '1', width: '100%', marginTop: '10px' }}
                                        variant={monthLimit === 6 ? 'primary' : 'secondary'}
                                        onClick={() => handleMonthLimitChange(6)}>
                                    6 Months
                                </Button>
                                <Button style={{ flex: '1', width: '100%', marginTop: '10px', marginBottom: '50px' }}
                                        variant={monthLimit === 12 ? 'primary' : 'secondary'}
                                        onClick={() => handleMonthLimitChange(12)}>
                                    12 Months
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
};

export default Leaderboards;
