import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import {useNavigate} from 'react-router-dom';
import './MoveDevice.css';
import BackButtonLogoHolder from "../BackButtonLogoHolder/BackButtonLogoHolder";

const ErrorModal = ({ show, errorMessage, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const DeviceRegistrationForm = (props) => {
    const [companies, setCompanies] = useState([]);
    const [formData, setFormData] = useState({
        registerType: 'IC7',
        vehicleReference: '',
        deviceReference: '',
        accountReference: '',
    });
    const [touched, setTouched] = useState({
        vehicleReference: false,
        deviceReference: false,
        accountReference: false,
    })
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleBlur = (e) => {
        setTouched({
            ...touched,
            [e.target.name]: true
        });
    };

    const isValid = (name) => formData[name].trim() !== '' && touched[name];

    const inputClass = (name) => {
        if (!touched[name]) return 'form-control';
        return isValid(name) ? 'form-control is-valid' : 'form-control is-invalid';
    };

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await fetch('/api/CompanyManagement/GetAllCompanies', {
                    headers: {
                        'Authorization': 'Bearer ' + props.userAccessToken
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch companies');
                }
                const data = await response.json();
                setCompanies(data);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.userAccessToken
            },
            body: JSON.stringify({
                ...formData,
            })
        };

        try {
            const response = await fetch('api/Register/RegisterDevice', requestOptions);

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.errorMessage || `HTTP error! Status: ${response.status}`);
            }

            setFormData({
                vehicleReference: '',
                deviceReference: '',
                accountReference: '',
            });
            navigate('/vehicle-management');
        } catch (error) {
            console.error('Error during device registration:', error);
            setError(error.message);
            setShowModal(true);
        }
    };


    return (
        <div>
            <ErrorModal show={showModal} errorMessage={error} onHide={() => setShowModal(false)} />

            <div className="webcontainer">
                <Container fluid>
                    <Row>
                        <Col md={3} lg={3}>
                            <BackButtonLogoHolder backlink="/vehicle-management"/>
                        </Col>
                        <Col md={6} lg={6} className="">
                            <div className="container mt-5">
                                <form onSubmit={handleSubmit} className="form-container">
                                    <h2>Register IC7 Device</h2>
                                    <div className="mb-3">
                                        <label htmlFor="accountReference" className="form-label">
                                            Account Reference:
                                        </label>
                                        <input type="text" className={inputClass('accountReference')}
                                               name="accountReference" id="accountReference"
                                               value={formData.accountReference} onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="vehicleReference" className="form-label">
                                            Vehicle Reference:
                                        </label>
                                        <input type="text" className={inputClass('vehicleReference')}
                                               name="vehicleReference" id="vehicleReference"
                                               value={formData.vehicleReference} onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="deviceReference" className="form-label">
                                            Device Reference:
                                        </label>
                                        <input type="text" className={inputClass('deviceReference')}
                                               name="deviceReference" id="deviceReference"
                                               value={formData.deviceReference} onChange={handleChange}
                                               onBlur={handleBlur}/>
                                    </div>

                                    <button type="submit" className="btn btn-primary">Register</button>
                                </form>
                            </div>
                        </Col>
                        <Col md={3} lg={3} className=""></Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default DeviceRegistrationForm;
