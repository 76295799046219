import React, { Component } from 'react';
import "./Branding.css"

/**
 * The not system admin class
 */
export class NotSystemAdmin extends Component {
    constructor(props) {
        document.title = "Not System Admin";
        super(props);
       
    }
    
    render() {
        return (
                <div className="unauthorised">
                    <h2>You are not a system administrator</h2>
                    <br/>
                    <h2>The page you are trying to view is only accessible to system admins</h2>
                </div>
        )
    }
}