import React, { useState, useEffect, useCallback } from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import { Button, Form, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';

const EditCompany = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParam = location.search.substring(1);
    const name = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [NewCompanyData, setNewCompanyData] = useState([]);
    const [CompanyData, setCompanyData] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [customerReference, setCustomerReference] = useState("");

    useEffect(() => {
        document.title = "Edit Company";
        getCompany(name);
    }, [name]);

    const getCompany = useCallback(async (companyName) => {
        const response = await fetch(`/api/CompanyManagement/GetSingleCompany?CompanyName=${companyName}`, GetHttpConfig(props.userAccessToken));
        const data = await response.json();
        setCompanyData(data);
        setCompanyName(data.name);
        setCustomerReference(data.customerReference);
    }, [props.userAccessToken]);

    const handleEditCompany = useCallback(async (oldCompanyName, newCompanyName, customerReference, e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken },
            body: JSON.stringify({ oldName: oldCompanyName, name: newCompanyName, customerReference: customerReference })
        };
        const response = await fetch('/api/CompanyManagement/EditCompany', requestOptions);
        if (response.ok) {
            navigate("/admin/companymanagement");
        }
    }, [props.userAccessToken, navigate]);

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{ textAlign: 'center' }}>
                            <h5>Edit Company - {name}</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={(e) => handleEditCompany(name, companyName, customerReference, e)} className="form-container">
                                <Form.Group controlId="companyEdit">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={companyName}
                                        placeholder="Enter the Company Name"
                                        onChange={(e) => setCompanyName(e.target.value)} />

                                    <br />

                                    <Form.Label>Customer Reference</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={customerReference}
                                        placeholder="Enter the Customer Reference"
                                        onChange={(e) => setCustomerReference(e.target.value)} />

                                    <br /><br />

                                    <Button variant="primary" type="submit">Edit Company</Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default EditCompany;
