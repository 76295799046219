import React, {useCallback, useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';
import '../User/EditUser.css'

const AddVehicleGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlParam = location.search.substring(1);
    const initialGroupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [NewVehicleGroupData, setNewVehicleGroupData] = useState([]);
    const [DefaultGroupName, setDefaultGroupName] = useState(initialGroupName);
    const [vehicleReference, setVehicleReference] = useState('');
    const [GroupName, setGroupName] = useState('');

    useEffect(() => {
        document.title = "Add Vehicle Group";
        getAllGroups();
    }, []);

    const getAllGroups = useCallback(async () => {
        try {
            const response = await fetch(`/api/GroupManagement/GetAllGroups`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setNewVehicleGroupData(data);
            setGroupName(data[0]?.groupName);
        } catch (error) {
            console.error(error);
        }
    }, [props.userAccessToken]);

    const handleCreateVehicleGroup = async (e) => {
        e.preventDefault();
        let finalGroupName = GroupName === null || GroupName === "Select Group"
            ? (DefaultGroupName === "" || DefaultGroupName == null ? data[0]?.groupName : DefaultGroupName)
            : GroupName;

        const redirectUrl = "/admin/vehiclegroup?" + encodeURIComponent(finalGroupName);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
            body: JSON.stringify({vehicleReference, groupName: finalGroupName})
        };

        try {
            const response = await fetch('/api/GroupManagement/AddVehicleGroup', requestOptions);
            if (response.ok) {
                navigate(redirectUrl);
            } else {
                throw new Error("Failed to add vehicle to group");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleVehicleReferenceChange = (event) => {
        setVehicleReference(event.target.value);
    };

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{textAlign: 'center'}}>
                            <h5>Add Vehicle Group</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateVehicleGroup} className="form-container">
                                <Form.Group controlId="newVehicleReference">
                                    <Form.Label>Vehicle</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={vehicleReference}
                                        required
                                        placeholder="Enter a Vehicle"
                                        onChange={handleVehicleReferenceChange}/>
                                    <Form.Text className="text-muted">
                                        Must be an existing vehicle.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="group">
                                    <Form.Label>Group</Form.Label>
                                    <select className="form-control" onChange={handleGroupNameChange}>
                                        <option>Select Group</option>
                                        {NewVehicleGroupData.map(group => (
                                            <option value={group.groupName} key={group.groupName}>
                                                {group.groupName}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>

                                <Button variant="primary" type="submit">Add Vehicle To Group</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddVehicleGroup;
