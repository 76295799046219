import React, {useEffect, useRef, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Dropdown, DropdownButton, Form, Row} from 'react-bootstrap';
import Loading from "../Loading";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import './LastActivity.css';
import moment from 'moment';
import markerBlue from '../images/hatchback.png';
import Moment from "react-moment";

const LastActivity = (props) => {
    document.title = "AGMT | Vehicle Map";
    const [state, setState] = useState({
        LastActivityData: [],
        loading: true,
        error: false,
        pageSize: 30,
        pageNumber: 1,
        totalResults: 0,
        loadedResults: 0,
        numberOfPages: 1,
        vehicleReference: '',
        accountReference: '',
    });
    const userEmail = props.userEmail;
    const [markersByVehicle, setMarkersByVehicle] = useState({});
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markers = useRef([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [vehicleReference, setVehicleReference] = useState('');
    const [accountReference, setAccountReference] = useState('');
    const [hoveredRow, setHoveredRow] = useState(null);
    const [isSearchVisible, setIsSearchVisible] = useState(true);
    const [refreshInterval, setRefreshInterval] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [totalElapsedTime, setTotalElapsedTime] = useState(0);


    useEffect(() => {
        if (mapContainer.current) initMap();
    }, [mapContainer.current]);

    useEffect(() => {
        if (state.pageNumber === 1) {
            getVehicles(state.pageSize, state.pageNumber);
        }
    }, [state.vehicleReference, state.accountReference]);

    useEffect(() => {
        if (fetchingData) {
            getVehicles(state.pageSize, state.pageNumber);
            setFetchingData(false);
        }
    }, [fetchingData]);

    useEffect(() => {
        if (refreshInterval !== null) {
            if (intervalId) clearInterval(intervalId);

            setTimeLeft(refreshInterval);
            const maxRefreshTime = 3600000;
            const newIntervalId = setInterval(() => {
                if (totalElapsedTime < maxRefreshTime) {
                    getVehicles(state.pageSize, state.pageNumber);
                    setTimeLeft(refreshInterval);
                    setTotalElapsedTime(prevTime => prevTime + refreshInterval);
                } else {
                    clearInterval(newIntervalId);
                    setTimeLeft(null);
                }
            }, refreshInterval);

            setIntervalId(newIntervalId);
        } else if (intervalId) {
            clearInterval(intervalId);
            setTimeLeft(null);
        }

        return () => clearInterval(intervalId);
    }, [refreshInterval, totalElapsedTime]);

    useEffect(() => {
        const timerId = timeLeft > 0 ? setInterval(() => {
            setTimeLeft(timeLeft - 1000);
        }, 1000) : null;

        return () => clearInterval(timerId);
    }, [timeLeft]);


    const getVehicles = (pageSize, pageNumber) => {
        setState(prevState => ({
            ...prevState,
            loading: true,
            error: false,
        }));

        fetch(`/api/LastLocation/GetLastLocation?PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}&VehicleReference=${state.vehicleReference}&AccountReference=${state.accountReference}`, GetHttpConfig(props.userAccessToken))
            .then(response => response.json())
            .then(data => {
                markers.current.forEach(marker => marker.remove());
                markers.current = [];
                for (let prop in markersByVehicle) {
                    if (Object.prototype.hasOwnProperty.call(markersByVehicle, prop)) {
                        delete markersByVehicle[prop];
                    }
                }

                setState(prevState => ({
                    ...prevState,
                    LastActivityData: data.results,
                    error: false,
                    loading: false,
                    totalResults: data.pageDetail.totalResults,
                    numberOfPages: data.pageDetail.numberOfPages,
                    pageNumber: data.pageDetail.pageNumber,
                    loadedResults: prevState.loadedResults + data.results.length
                }));

                const newMarkersByVehicle = {};
                data.results.forEach(result => {
                    if (result.endLatitude >= -90 && result.endLatitude <= 90 && result.endLongitude >= -180 && result.endLongitude <= 180) {
                        const lastSeen = moment(result.endDateTime).fromNow();
                        let markerImage = markerBlue;
                        const el = document.createElement('div');
                        el.className = 'marker';

                        const img = document.createElement('img');
                        img.src = markerImage;
                        img.style.width = '30px';
                        img.style.height = '30px';

                        const p = document.createElement('p');
                        p.innerHTML = result.vehicleReference;
                        p.style.fontSize = '11px';
                        p.style.textAlign = 'center';
                        p.style.width = '150px';

                        el.appendChild(p);
                        el.appendChild(img);

                        const tooltip = document.createElement('p');
                        tooltip.className = 'marker-tooltip';
                        tooltip.innerHTML = result.vehicleReference + '<br/>' + 'Last Position: ' + lastSeen;
                        tooltip.style.textAlign = 'center';
                        tooltip.style.width = '150px';
                        tooltip.style.display = 'none';
                        el.appendChild(tooltip);

                        el.addEventListener('mouseenter', () => {
                            tooltip.style.display = 'block';
                        });

                        el.addEventListener('mouseleave', () => {
                            tooltip.style.display = 'none';
                        });

                        const marker = new mapboxgl.Marker(el)
                            .setLngLat([result.endLongitude, result.endLatitude])
                            .setPopup(new mapboxgl.Popup({offset: 50})
                                .setHTML(`
                                <div class="dark-popup">
                                    <a href="/deviceOverview/${result.vehicleReference}">
                                        <button>Overview</button>
                                    </a>
                                    <a href="/vehicles/${result.vehicleReference}">
                                        <button>Journeys</button>
                                    </a>
                                </div>
                            `))
                            .addTo(map.current);

                        newMarkersByVehicle[result.vehicleReference] = marker;
                        markers.current.push(marker);
                    } else {
                        console.error('Invalid coordinates: ', result);
                    }
                });
                setMarkersByVehicle(newMarkersByVehicle);
            })
            .catch(error => {
                console.error(error);
                setState({
                    LastActivityData: null,
                    loading: false,
                    error: true,
                    totalResults: 0,
                    numberOfPages: 1,
                    pageNumber: 1,
                });
            });
    };

    const handleVehicleReferenceChange = (event) => {
        setVehicleReference(event.target.value);
    };

    const handleAccountReferenceChange = (event) => {
        setAccountReference(event.target.value);
    };

    const toggleSearchVisibility = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const handleFilterSubmit = (event) => {
        event.preventDefault();

        markers.current.forEach(marker => marker.remove());
        markers.current = [];

        for (let prop in markersByVehicle) {
            if (Object.prototype.hasOwnProperty.call(markersByVehicle, prop)) {
                delete markersByVehicle[prop];
            }
        }

        const updatedState = {
            ...state,
            pageNumber: 1,
            LastActivityData: [],
            loadedResults: 0,
            vehicleReference,
            accountReference,
        };

        setState(updatedState);

        setFetchingData(true);
    };

    const clearResults = (event) => {
        event.preventDefault();

        const updatedState = {
            LastActivityData: [],
            loading: true,
            error: false,
            pageSize: 30,
            pageNumber: 1,
            totalResults: 0,
            loadedResults: 0,
            numberOfPages: 1,
            vehicleReference: '',
            accountReference: '',
        };

        setState(updatedState);

        markers.current.forEach(marker => marker.remove());
        markers.current = [];

        for (let prop in markersByVehicle) {
            if (Object.prototype.hasOwnProperty.call(markersByVehicle, prop)) {
                delete markersByVehicle[prop];
            }
        }

        setFetchingData(true);
    };

    const filterByUserEmail = () => {
        setAccountReference(userEmail);
        markers.current.forEach(marker => marker.remove());
        markers.current = [];

        for (let prop in markersByVehicle) {
            if (Object.prototype.hasOwnProperty.call(markersByVehicle, prop)) {
                delete markersByVehicle[prop];
            }
        }

        const updatedState = {
            ...state,
            pageNumber: 1,
            LastActivityData: [],
            loadedResults: 0,
            vehicleReference: '',
            accountReference: userEmail,
        };

        setState(updatedState);

        setFetchingData(true);
    };

    const zoomToVehicle = (vehicleReference) => {
        const marker = markersByVehicle[vehicleReference];
        if (marker) {
            map.current.flyTo({center: marker.getLngLat(), zoom: 14, pitch: 40});
        }
    };

    function isInLastTenMinutes(date) {
        const TEN_MINUTES = 600000;
        const now = new Date();
        const lastUpdatedDate = new Date(date);

        return (now - lastUpdatedDate) < TEN_MINUTES;
    }

    const handlePageSizeChange = (newPageSize) => {
        setState(prevState => ({
            ...prevState,
            pageSize: newPageSize,
            pageNumber: 1,
        }));
        setFetchingData(true);
    };

    const initMap = () => {
        mapboxgl.accessToken = 'pk.eyJ1Ijoid2FsZm9yZCIsImEiOiJjanNkYWQ2bHgwdDdkNGFuenZvZGp3MHgwIn0.Tim2j2qijkniUr0_1H497w';
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            zoom: 1.5,
        });
        map.current.addControl(new mapboxgl.FullscreenControl());
    };

    const handleRefreshIntervalChange = (interval) => {
        setRefreshInterval(interval);
    };

    const refreshTitle = () => {
        if (timeLeft === null) {
            return "Refresh Interval";
        } else {
            const secondsLeft = Math.max(Math.round(timeLeft / 1000), 0);
            return `Refreshing in ${secondsLeft} sec`;
        }
    };

    const optionsDropdown = [
        {value: '5000', label: '1 Minute'},
        {value: '300000', label: '5 Minutes'},
        {value: '600000', label: '10 Minutes'},
        {value: null, label: 'Stop Auto Refresh', color: '#de350b'},
    ];

    function handleDropdownChange(selectedOption) {
        handleRefreshIntervalChange(selectedOption.value);
    }


    let content = null;
    if (state.loading) {
        content = <Loading/>;
    } else if (state.error) {
        content = <p>There was an error.</p>;
    } else if (state.LastActivityData.length === 0) {
        content = <p>No crashes found.</p>;
    } else {
        content = <div className=""></div>;
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3" style={{textAlign: 'center'}}>
                            <h4>Vehicle Map</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={4} className="vehicle-list-table">
                        <div className="contentsearch">
                            <div className="tileCharts">
                                <Button type="button" variant="primary" onClick={filterByUserEmail}
                                        className="full-button">
                                    My Vehicles
                                </Button>
                                <Button onClick={toggleSearchVisibility} className="full-button"> Show Search </Button>
                                <DropdownButton
                                    id="dropdown-refresh-interval"
                                    title={refreshTitle()}
                                    variant={refreshInterval ? "success" : "primary"}
                                    className="full-width-dropdown"
                                >
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(60000)}>1
                                        Minute</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(300000)}>5
                                        Minutes</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(600000)}>10
                                        Minutes</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRefreshIntervalChange(null)} variant="danger">Stop
                                        Auto Refresh</Dropdown.Item>
                                </DropdownButton>
                                <DropdownButton id="dropdown-page-size" className="full-width-dropdown"
                                                title={`${state.pageSize} Vehicles`} variant="primary">
                                    <Dropdown.Item onClick={() => handlePageSizeChange(30)}>30 Vehicles</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handlePageSizeChange(60)}>60 Vehicles</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handlePageSizeChange(100)}>100
                                        Vehicles</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            {!isSearchVisible && (
                                <Form onSubmit={handleFilterSubmit}>
                                    <Form.Group controlId="accountReference">
                                        <Form.Control type="text" placeholder="Enter Account"
                                                      onChange={handleAccountReferenceChange} value={accountReference}/>
                                    </Form.Group>
                                    <Form.Group controlId="vehicleReference">
                                        <Form.Control type="text" placeholder="Enter Vehicle"
                                                      onChange={handleVehicleReferenceChange} value={vehicleReference}/>
                                    </Form.Group>

                                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                        <Button variant="primary" type="submit" className="full-button">
                                            Search
                                        </Button>

                                        <Button variant="secondary" onClick={clearResults} className="full-button">
                                            Clear
                                        </Button>
                                    </div>
                                </Form>
                            )}
                            <div className="chart-wrapper-3"></div>
                            <div className="table-responsive">
                                <table className="table table-striped table-dark">
                                    <thead>
                                    <tr>
                                        <th>Vehicle</th>
                                        <th>Last Journey End</th>
                                        <th>Last Journey Position</th>
                                        <th>In journey</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {state.LastActivityData.map((item, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => zoomToVehicle(item.vehicleReference)}
                                            onMouseEnter={() => setHoveredRow(index)}
                                            onMouseLeave={() => setHoveredRow(null)}
                                            style={hoveredRow === index ? {backgroundColor: "#094f7e"} : {}}
                                        >
                                            <td>{item.vehicleReference}</td>
                                            <td><Moment fromNow>{item.lastUpdatedDateTime}</Moment></td>
                                            <td><Moment fromNow>{item.endDateTime}</Moment></td>
                                            <td>{isInLastTenMinutes(item.endDateTime) ? '\u2713' : ''}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Col>

                    <Col md={12} lg={8} className="map-full">

                        <div className="fixed-col-vehicle-view">
                            <div id="mapContainer" ref={mapContainer} style={{width: '100%', height: '100%'}}></div>

                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
};

export default LastActivity;