import React, { Component } from 'react';
import "./Branding.css"

/**
 * The request Access class
 */
export class RequestAccess extends Component {
    constructor(props) {
        document.title = "Request Access";
        super(props);
       
    }
    
    render() {
        return (
                <div className="unauthorised">
                    <h2>You are not authorised to access UBI Dashboard</h2>
                    <br/>
                    <h2>Please request access from your system administrator</h2>
                    <h2>Or contact info@agmtechnologies.co.uk</h2>
                </div>
        )
    }
}