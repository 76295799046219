
export function getAverageScores(JourneyScoresLineData) {
    if (!JourneyScoresLineData || JourneyScoresLineData.length === 0) return {};

    let averages = {};
    for (let journey of JourneyScoresLineData) {
        let sum = 0;
        let count = 0;
        for (let i = 0; i < 12; i++) {
            let monthValue = journey['month' + i];
            if (monthValue) {
                sum += monthValue;
                count++;
            }
        }
        averages[journey.analysisTypesId] = count > 0 ? sum / count : 0;
    }

    return averages;
}

export function getTotal(dataKey, state) {
    const data = state[dataKey];
    if (!data || Object.keys(data).length === 0) return 0;

    return Object.entries(data)
        .filter(([key, value]) => typeof value === 'number' && key !== 'analysisTypesId')
        .reduce((total, [_, value]) => total + value, 0);
}

export function getPastTwelveMonths() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
        const year = date.getFullYear().toString().slice(-2);
        months.unshift(monthNames[date.getMonth()] + ' ' + year);
        date.setMonth(date.getMonth() - 1);
    }

    return months;
}

export function getAverageSpeeding(SpeedingAverage) {
    if (!SpeedingAverage) return 0;

    let sum = 0;
    let count = 0;

    for (const key in SpeedingAverage) {
        const monthValue = SpeedingAverage[key];
        if (monthValue) {
            sum += monthValue;
            count++;
        }
    }

    return count > 0 ? sum / count : 0;
}

export function mapToLineData(journeyScoresData) {
    return journeyScoresData.map(item => ({
        analysisTypesId: item.analysisTypesId,
        month0: item.month0 || 0,
        month1: item.month1 || 0,
        month2: item.month2 || 0,
        month3: item.month3 || 0,
        month4: item.month4 || 0,
        month5: item.month5 || 0,
        month6: item.month6 || 0,
        month7: item.month7 || 0,
        month8: item.month8 || 0,
        month9: item.month9 || 0,
        month10: item.month10 || 0,
        month11: item.month11 || 0
    }));
}

