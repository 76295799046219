import React, {useCallback, useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import './EditUser.css';

const AddUser = (props) => {
    const [NewUsersData, setNewUsersData] = useState([]);
    const [DefaultCompany, setDefaultCompany] = useState(null);
    const [username, setUsername] = useState("");
    const [companyname, setCompanyname] = useState("");
    const [speedUnit, setSpeedUnit] = useState("0");

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Add User";
        getAllCompanies();
    }, []);

    const getAllCompanies = useCallback(async () => {
        try {
            const response = await fetch(`/api/UserManagement/GetAllCompanies`, GetHttpConfig(props.userAccessToken));
            if (!response.ok) {
                throw new Error("Failed to fetch companies");
            }
            const data = await response.json();
            setNewUsersData(data);
            setDefaultCompany(data[0]?.name);
        } catch (error) {
            console.error(error);
            // Handle error here
        }
    }, [props.userAccessToken]);

    const handleCreateUser = useCallback(async (e) => {
        e.preventDefault();
        let companyName = !companyname || companyname === "Select Group" ? DefaultCompany : companyname;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
            body: JSON.stringify({username, companyName, isAdmin: false, speedUnit})
        };
        try {
            const response = await fetch('/api/UserManagement/AddUser', requestOptions);
            if (!response.ok) {
                throw new Error("Failed to add user");
            }
            navigate("/admin/usermanagement");
        } catch (error) {
            console.error(error);
        }
    }, [companyname, DefaultCompany, username, props.userAccessToken, navigate, speedUnit]);

    const handleUsernameChange = useCallback((event) => {
        setUsername(event.target.value);
    }, []);

    const handleCompanynameChange = useCallback((selectedCompany) => {
        setCompanyname(selectedCompany);
    }, []);

    const handleSpeedUnitChange = (event) => {
        setSpeedUnit(event.target.value);
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Add User</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateUser} className="form-container">
                                <Form.Group controlId="newUsername">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={username}
                                        required
                                        placeholder="Enter a Username"
                                        onChange={handleUsernameChange}/>
                                </Form.Group>

                                <Form.Group controlId="company">
                                    <Form.Label>Company:</Form.Label>
                                    <Form.Control as="select" value={companyname}
                                                  onChange={(e) => handleCompanynameChange(e.target.value)}>
                                        <option>Select Company</option>
                                        {NewUsersData.map(company => (
                                            <option value={company.name} key={company.name}>{company.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="speedUnit">
                                    <Form.Label>Measurement Unit</Form.Label>
                                    <Form.Control as="select" value={speedUnit} onChange={handleSpeedUnitChange}>
                                        <option value="0">Imperial</option>
                                        <option value="1">Metric</option>
                                    </Form.Control>
                                </Form.Group>

                                <Button variant="primary" type="submit">Create User</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddUser;