import React, {useCallback, useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {Paging} from '../Paging';
import Loading from "../Loading";
import {Link} from "react-router-dom";

const GroupManagement = (props) => {
    document.title = "UBI Group Management";

    const [GroupsData, setGroupsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [groupName, setGroupName] = useState('');

    const getGroups = useCallback(async (pageSize, pageNumber) => {
        try {
            const response = await fetch(`/api/GroupManagement/GetGroups?GroupName=${groupName}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setGroupsData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            setGroupsData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [groupName, props.userAccessToken]);

    useEffect(() => {
        getGroups(pageSize, pageNumber);
    }, [getGroups, pageSize, pageNumber]);

    const searchGroups = (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(null);
        getGroups(pageSize, 1);
    };

    const clearSearch = () => {
        setGroupName('');
        getGroups(pageSize, pageNumber);
    };

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    const handleDeleteGroup = async (groupName) => {
        if (window.confirm('Do you want to delete ' + groupName + '?')) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
                body: JSON.stringify({groupName})
            };
            await fetch('/api/GroupManagement/DeleteGroup', requestOptions);
            getGroups(pageSize, pageNumber);
        }
    };

    let content = null;
    if (loading) {
        content = <Loading/>;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (GroupsData.length === 0) {
        content = <p>No group found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Edit</th>
                        <th>Users</th>
                        <th>Vehicles</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {GroupsData.map(group => (
                        <tr key={group.groupId}>
                            <td>{group.groupName}</td>
                            <td><Link variant="warning" className="btn btn-outline-warning" type="button"
                                      to={"/admin/editgroup?" + group.groupName}>Edit Group</Link></td>
                            <td><Link variant="secondary" className="btn btn-outline-primary" type="submit"
                                      to={"/admin/usergroup?" + group.groupName}>Users</Link></td>
                            <td><Link variant="secondary" className="btn btn-outline-primary" type="submit"
                                      to={"/admin/vehiclegroup?" + group.groupName}>Vehicles</Link></td>
                            <td><Button variant="" className="btn btn-outline-danger" type="button"
                                        onClick={() => handleDeleteGroup(group.groupName)}>Delete</Button></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>Group Management</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h5>Search</h5>
                            <Form onSubmit={searchGroups}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={groupName} onChange={handleGroupNameChange}
                                                  placeholder="Enter a Group"/>
                                </Form.Group>
                            </Form>
                            <Button variant="primary" type="submit" onClick={searchGroups}
                                    className="mr-3">Search</Button>
                            <Button variant="secondary" type="button" onClick={clearSearch}>Clear</Button>
                        </div>
                        <div className="myvehicles-button">
                            <Link to="/admin/addgroup">
                                <Button variant="success" className="full-button" size="">Add Group</Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={e => getGroups(pageSize, e)}
                        pageSizeChange={e => getGroups(e, 1)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default GroupManagement;
