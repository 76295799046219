import React, {useCallback, useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';

const AddUserGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const urlParam = location.search.substring(1);
    const initialGroupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [NewUserGroupData, setNewUserGroupData] = useState([]);
    const [DefaultGroupName, setDefaultGroupName] = useState(initialGroupName);
    const [username, setUsername] = useState('');
    const [GroupName, setGroupName] = useState('');

    useEffect(() => {
        document.title = "Add User Group";
        getAllGroups();
    }, []);

    const getAllGroups = useCallback(async () => {
        try {
            const response = await fetch(`/api/GroupManagement/GetAllGroups`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setNewUserGroupData(data);
            setGroupName(data[0]?.groupName);
        } catch (error) {
            console.error(error);
        }
    }, [props.userAccessToken]);

    const handleCreateUserGroup = async (e) => {
        e.preventDefault();
        let finalGroupName = GroupName === null || GroupName === "Select Group"
            ? (DefaultGroupName === "" || DefaultGroupName == null ? data[0]?.groupName : DefaultGroupName)
            : GroupName;

        const redirectUrl = "/admin/usergroup?" + encodeURIComponent(finalGroupName);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
            body: JSON.stringify({username, groupName: finalGroupName})
        };

        try {
            const response = await fetch('/api/GroupManagement/AddUserGroup', requestOptions);
            if (response.ok) {
                navigate(redirectUrl);
            } else {
                throw new Error("Failed to add user group");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleGroupNameChange = (event) => {
        setGroupName(event.target.value);
    };

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12}>
                        <div className="contentvehicle3" style={{textAlign: 'center'}}>
                            <h5>Add User Group</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} lg={3}></Col>
                    <Col md={6}>
                        <div className="container mt-5">
                            <Form onSubmit={handleCreateUserGroup} className="form-container">
                                <Form.Group controlId="newUsername">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={username}
                                        required
                                        placeholder="Enter a Username"
                                        onChange={handleUsernameChange}/>
                                    <Form.Text className="text-muted">
                                        Must be an existing user.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group controlId="group">
                                    <Form.Label>Group</Form.Label>
                                    <select className="form-control" onChange={handleGroupNameChange}>
                                        <option>Select Group</option>
                                        {NewUserGroupData.map(group => (
                                            <option value={group.groupName} key={group.groupName}>
                                                {group.groupName}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>

                                <Button variant="primary" type="submit">Create User Group</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col md={3} lg={3}></Col>
                </Row>
            </Container>
        </div>
    );
};

export default AddUserGroup;