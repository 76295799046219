import React, {useCallback, useEffect, useState} from 'react';
import GetHttpConfig from "../Helpers/GetHttpConfig";
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import {Paging} from '../Paging';
import Loading from "../Loading";
import {Link, useLocation, useNavigate} from 'react-router-dom';

const UserGroup = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParam = location.search.substring(1);
    const groupName = decodeURIComponent(urlParam).replace(/%20/g, " ");

    const [UserGroupsData, setUserGroupsData] = useState([]);
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(1);

    useEffect(() => {
        document.title = "UBI User Group Management";
        getUserGroups(pageSize, pageNumber, groupName);
    }, [pageSize, pageNumber, groupName]);

    const getUserGroups = useCallback(async (pageSize, pageNumber, groupName) => {
        try {
            const response = await fetch(`/api/GroupManagement/GetUserGroups?GroupName=${groupName}&Username=${username}&PagingRequest.PageSize=${pageSize}&PagingRequest.PageNumber=${pageNumber}`, GetHttpConfig(props.userAccessToken));
            const data = await response.json();
            setUserGroupsData(data.results);
            setLoading(false);
            setError(false);
            setTotalResults(data.pageDetail.totalResults);
            setNumberOfPages(data.pageDetail.numberOfPages);
            setPageNumber(data.pageDetail.pageNumber);
        } catch (error) {
            setUserGroupsData([]);
            setLoading(false);
            setError(true);
            setTotalResults(0);
            setNumberOfPages(1);
            setPageNumber(1);
        }
    }, [username, props.userAccessToken]);

    const searchGroups = (event) => {
        event.preventDefault();
        setLoading(true);
        setTotalResults(null);
        setNumberOfPages(null);
        setPageNumber(null);
        getUserGroups(pageSize, 1, groupName);
    };

    const clearSearch = () => {
        setUsername('');
        getUserGroups(pageSize, pageNumber, groupName);
    };

    const handleUserNameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleDeleteUserGroup = async (userGroupId, username) => {
        if (window.confirm('Do you want to remove ' + username + ' from this group?')) {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + props.userAccessToken},
                body: JSON.stringify({userGroupId})
            };
            await fetch('/api/GroupManagement/DeleteUserGroup', requestOptions);
            getUserGroups(pageSize, pageNumber, groupName);
        }
    };

    let content = null;
    if (loading) {
        content = <Loading/>;
    } else if (error) {
        content = <p>There was an error.</p>;
    } else if (UserGroupsData.length === 0) {
        content = <p>No user groups found.</p>;
    } else {
        content = (
            <div className="content-vehicle-list">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th>Username</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {UserGroupsData.map(userGroup => (
                        <tr key={userGroup.userGroupId}>
                            <td>{userGroup.username}</td>
                            <td>
                                <Button variant="outline-danger" type="button"
                                        onClick={() => handleDeleteUserGroup(userGroup.userGroupId, userGroup.username)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="webcontainer">
            <Container fluid>
                <Row>
                    <Col md={12} lg={12} className="fixed-col2">
                        <div className="contentvehicle3">
                            <h4>User Group Management: {groupName}</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={2}>
                        <div className="contentsearch mt-5">
                            <h5>Search</h5>
                            <Form onSubmit={searchGroups}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={username} onChange={handleUserNameChange}
                                                  placeholder="Enter a Username"/>
                                </Form.Group>
                            </Form>
                            <Button variant="primary" type="submit" onClick={searchGroups}
                                    className="mr-3">Search</Button>
                            <Button variant="secondary" type="button" onClick={clearSearch}>Clear</Button>
                        </div>
                        <div className="myvehicles-button">
                            <Link to={"/admin/addusergroup?" + groupName}>
                                <Button variant="success" className="full-button" size="">Add User To Group</Button>
                            </Link>
                        </div>
                    </Col>
                    <Paging
                        content={content}
                        pageSize={pageSize}
                        pageNumber={pageNumber}
                        totalResults={totalResults}
                        numberOfPages={numberOfPages}
                        onChange={(e) => getUserGroups(pageSize, e, groupName)}
                        pageSizeChange={(e) => getUserGroups(e, 1, groupName)}
                    />
                </Row>
            </Container>
        </div>
    );
};

export default UserGroup;
